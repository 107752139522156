import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { CommentSection, OverlayPage, OverlayPageContents, OverlayPageSection, Text } from '../../components';
import { reactionActions } from '../../webapi';
import { safeReadParams } from '../../helper';

class Thread extends Component {
  constructor(props) {
    super(props);

    const threadId = safeReadParams(props, 'threadId');
    const threadSplit = threadId.split('_');
    const entityType = _.last(threadSplit);
    threadSplit.pop();
    const entityId = threadSplit.join('_');

    this.state = {
      comments: [],
      threadId,
      entityType,
      entityId,
    };
  }

  componentDidMount() {
    this.connect();
  }

  componentWillUnmount() {
    this.disconnect();
  }

  connect = () => {
    this.getComments();

    this.commentsInterval = setInterval(this.getComments, 2000);
  };

  disconnect = () => {
    clearInterval(this.commentsInterval);
  };

  onBack = () => {
    window.history.back();
  };

  getEntityName = () => {
    if (_.isEmpty(this.state.comments)) return null;
    const commentWithName = _.find(this.state.comments, (c) => {
      return !_.isEmpty(c.EntityName);
    });
    if (!commentWithName) return null;
    return commentWithName.EntityName;
  };

  getSite = () => {
    if (_.isEmpty(this.state.comments)) return this.props.auth.site;
    const commentWithSite = _.find(this.state.comments, (c) => {
      return !_.isEmpty(c.Site);
    });
    if (!commentWithSite) return this.props.auth.site;
    return commentWithSite.Site;
  };

  onAddComment = (comment) => {
    reactionActions.addComment(this.state.entityId, this.state.entityType, this.getEntityName(), this.getSite(), comment);
  };

  getComments = async () => {
    const minTime = _.isEmpty(this.state.comments) ? 0 : _.last(this.state.comments).Timestamp + 1;
    reactionActions.getComments(this.state.entityId, this.state.entityType, minTime).then((res) => {
      const comments = _.uniqBy([...this.state.comments, ...res.data], (c) => c.Id);
      this.setState({
        comments: _.orderBy(comments, 'Timestamp'),
      });
    });
  };

  render() {
    return (
      <OverlayPage>
        <OverlayPageContents noBottomButtons>
          <OverlayPageSection className="pageSectionWrapper--fixedPopupSize">
            <div className="padding-60 paddingVertical-40">
              <div className="flex flex-center">
                <FontAwesome className="featurePicker_back" name="angle-left" onClick={this.onBack} />
                <Text type="formTitleLarge" className="featurePicker_pageTitle">
                  {this.getEntityName()}
                </Text>
              </div>
              <CommentSection comments={this.state.comments} onAddComment={this.onAddComment} />
            </div>
          </OverlayPageSection>
        </OverlayPageContents>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;

  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(withRouter(Thread));
