import React, { Component } from 'react';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import _ from 'lodash';
import { withRouter } from 'react-router';
import FontAwesome from 'react-fontawesome';
import { Button, GenericInput, Popup, SVGIcon } from '../../components';
import { setNavData, refreshAuthUser } from '../../actions';
import { validateAccess } from '../../session';
import { typeActions } from '../../webapi';
import { getUrlParams, safeReadParams } from '../../helper';
import { COLOUR_BRANDING_APP, COLOUR_BRANDING_MAIN, COLOUR_DUSK_LIGHTER } from '../../js';
import { OnboardingStep } from '../../components/onboarding';
import { Text } from '../../components/text';

class TVPicker extends Component {
  constructor(props) {
    super(props);

    const urlParams = getUrlParams();

    const interfaces = this.props.navData.interfaces || [];

    this.widgets = [
      { key: 'announcements', title: 'Announcements' },
      { key: 'events', title: 'Events' },
      { key: 'gallery', title: 'Gallery' },
      { key: 'food', title: 'Food' },
    ];

    this.state = {
      interfaceTitle: urlParams.title ? decodeURIComponent(urlParams.title) : null,
      interfaceId: safeReadParams(this.props, 'interfaceId') || (urlParams.interfaceId ? decodeURIComponent(urlParams.interfaceId) : null),
      interfaces,
      interfaceInput: '',
      selectedWidgets: [],
      isPortrait: false,
      flippedPortrait: false,
      loadedInterface: false,
      loading: !_.some(interfaces, (i) => {
        return i.Type === 'TV';
      }),
      anyChanges: false,
    };
  }

  componentDidMount() {
    if (!validateAccess(this.props.auth.site, 'featurePicker', this.props.auth)) {
      this.props.history.push('/mastermenu');
    }
    this.getSiteSettings();
    this.checkSelectInterface();
    this.refreshInterfaces();

    this.props.setNavData({ hideSideMenu: true });
  }

  componentWillUnmount() {
    this.props.setNavData({ hideSideMenu: false });
  }

  getSiteSettings() {
    if (this.props.navData.site && this.props.navData.site.Id === this.props.auth.site) {
      this.setSiteSettings(this.props.navData.site);
    } else {
      typeActions.getSite(this.props.auth.site).then((res) => {
        this.setSiteSettings(res.data);
      });
    }
  }

  setSiteSettings(site) {
    const newState = { site, colour: COLOUR_BRANDING_APP };
    if (!_.isEmpty(site.Branding)) {
      if (!_.isEmpty(site.Branding.MainBrandingColour)) {
        newState.colour = site.Branding.MainBrandingColour;
      }
      if (!_.isEmpty(site.Branding.Logo)) {
        newState.logo = site.Branding.Logo;
      }
    }
    this.setState(newState);
  }

  checkSelectInterface() {
    if (this.state.loadedInterface) {
      return;
    }
    const interfaces = this.getTVInterfaces();
    if (!_.isEmpty(interfaces)) {
      if (!this.state.interfaceId) {
        this.selectInterface(interfaces[0]);
        return;
      }
      const iFace = _.find(interfaces, (i) => {
        return i.Id === this.state.interfaceId;
      });
      if (iFace) {
        this.setState({
          loadedInterface: true,
          selectedWidgets: [...iFace.Settings.widgets],
          isPortrait: iFace.Settings.rotation && (iFace.Settings.rotation === -90 || iFace.Settings.rotation === 90),
          flippedPortrait: iFace.Settings.rotation && iFace.Settings.rotation === 90,
        });
      }
    }
  }

  refreshInterfaces() {
    typeActions.getInterfaces(this.props.auth.site).then((res) => {
      this.setState({
        interfaces: res.data,
        loading: false,
      });
      setTimeout(() => {
        this.checkSelectInterface();
      }, 50);
    });
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  onTogglePortrait = (isPortrait) => {
    this.setState({
      isPortrait,
      anyChanges: true,
    });
  };

  onToggleFlipPortrait = (flippedPortrait) => {
    this.setState({
      flippedPortrait,
      anyChanges: true,
    });
  };

  getTVInterfaces() {
    return _.filter(this.state.interfaces, (i) => {
      return i.Type === 'TV';
    });
  }

  selectWidget = (widget) => {
    if (_.includes(this.state.selectedWidgets, widget)) {
      return;
    }
    const newWidgets = [...this.state.selectedWidgets, _.clone(widget)];
    this.setState({
      selectedWidgets: newWidgets,
      anyChanges: true,
    });
  };

  deselectWidget = (widget) => {
    const newWidgets = [...this.state.selectedWidgets];
    const index = newWidgets.indexOf(widget);
    console.log(index);
    newWidgets.splice(index, 1);
    this.setState({
      selectedWidgets: newWidgets,
      anyChanges: true,
    });
  };

  editInterface = (iFace) => {
    this.setState({
      editingInterface: iFace,
      interfaceTitleInput: iFace.Title,
    });
  };

  goToDeleteInterface = () => {
    this.setState({
      showConfirmInterfaceDelete: true,
    });
  };

  cancelDeleteInterface = () => {
    this.setState({
      showConfirmInterfaceDelete: false,
    });
  };

  confirmDeleteInterface = () => {
    this.setState({
      savingInterface: true,
    });

    typeActions.deleteInterface(this.state.editingInterface.Id).then((res) => {
      this.refreshInterfaces();
      setTimeout(() => {
        const tvInterfaces = this.getTVInterfaces();
        this.selectInterface(!_.isEmpty(tvInterfaces) ? tvInterfaces[0] : null);
      }, 500);
    });
  };

  openAddInterface = () => {
    if (this.state.anyChanges && !window.confirm('You have unsaved changes. Are you sure you want to continue?')) {
      return;
    }
    this.setState({ showAddInterface: true, interfaceTitleInput: '' });
  };

  closeAddInterface = () => {
    this.setState({ showAddInterface: false });
  };

  saveNewInterface = () => {
    if (_.isEmpty(this.state.interfaceTitleInput)) {
      return;
    }
    this.setState({
      savingInterface: true,
    });
    typeActions
      .saveInterface(this.props.auth.site, 'TV', this.state.interfaceTitleInput, typeActions.getDefaultTVSettings())
      .then((res) => {
        this.selectInterface(res.data.result);
      })
      .catch((err) => {
        window.alert('Something went wrong');
        this.setState({
          savingInterface: false,
        });
      });
  };

  closeSuccessPopup = () => {
    this.setState({
      success: false,
    });
  };

  handleCancel = () => {
    if (this.state.anyChanges && !window.confirm('You have unsaved changes. Are you sure you want to continue?')) {
      return;
    }
    this.props.history.push('/');
  };

  startEditingWidgetTitle = (key) => {
    this.setState({
      isEditingWidgetTitle: key,
    });
  };

  endEditingWidgetTitle = () => {
    this.setState({
      isEditingWidgetTitle: null,
    });
  };

  handleWidgetTitleChange = (widget, value) => {
    widget.title = value;
    this.setState({
      selectedWidgets: [...this.state.selectedWidgets],
      anyChanges: true,
    });
  };

  getReorderActions(index) {
    if (this.state.selectedWidgets.length === 2) {
      switch (index) {
        case 0:
          return [{ icon: 'right', change: 1 }];
        case 1:
          return [{ icon: 'left', change: -1 }];
        default:
          break;
      }
    } else if (this.state.selectedWidgets.length === 3) {
      switch (index) {
        case 0:
          return [
            { icon: 'right', change: 1 },
            { icon: 'down', change: 2 },
          ];
        case 1:
          return [
            { icon: 'left', change: -1 },
            { icon: 'down', change: 1 },
          ];
        case 2:
          return [{ icon: 'up', change: -1 }];
        default:
          break;
      }
    } else if (this.state.selectedWidgets.length === 4) {
      switch (index) {
        case 0:
          return [
            { icon: 'right', change: 1 },
            { icon: 'down', change: 2 },
          ];
        case 1:
          return [
            { icon: 'left', change: -1 },
            { icon: 'down', change: 2 },
          ];
        case 2:
          return [
            { icon: 'right', change: 1 },
            { icon: 'up', change: -2 },
          ];
        case 3:
          return [
            { icon: 'left', change: -1 },
            { icon: 'up', change: -2 },
          ];
        default:
          break;
      }
    }
    return [];
  }

  moveWidget = (index, change) => {
    const sourceWidget = this.state.selectedWidgets[index];
    const targetIndex = index + change;
    const targetWidget = this.state.selectedWidgets[targetIndex];
    const newSelectedWidgets = [...this.state.selectedWidgets];
    newSelectedWidgets[targetIndex] = sourceWidget;
    newSelectedWidgets[index] = targetWidget;
    this.setState({
      selectedWidgets: newSelectedWidgets,
      anyChanges: true,
    });
  };

  compileData() {
    return {
      widgets: this.state.selectedWidgets.map((w) => {
        return { key: w.key, title: w.title };
      }),
      rotation: this.state.isPortrait ? (this.state.flippedPortrait ? 90 : -90) : 0,
    };
  }

  handleSubmit = () => {
    if (!this.validateForm()) {
      return;
    }
    this.setState({
      submitting: true,
    });
    typeActions
      .saveInterface(this.props.auth.site, undefined, undefined, this.compileData(), this.state.interfaceId)
      .then((res) => {
        this.props.refreshAuthUser(this.props.auth.site);
        this.setState({
          submitting: false,
          success: true,
          anyChanges: false,
        });
      })
      .catch((err) => {
        window.alert('Something went wrong');
        this.setState({
          submitting: false,
        });
      });
  };

  validateForm = () => {
    if (this.state.submitting) {
      return false;
    }
    if (!this.state.interfaceId) {
      return false;
    }
    if (_.isEmpty(this.state.selectedWidgets)) {
      return false;
    }
    return true;
  };

  goToAppPicker = () => {
    if (this.state.anyChanges && !window.confirm('You have unsaved changes. Are you sure you want to continue?')) {
      return;
    }
    this.props.setNavData({ site: this.state.site, interfaces: this.state.interfaces, userTypes: this.state.userTypes });
    setTimeout(() => {
      this.props.history.push(`/featurepicker`);
    }, 50);
  };

  selectInterface = (iFace) => {
    if (this.state.anyChanges && !window.confirm('You have unsaved changes. Are you sure you want to continue?')) {
      return;
    }
    this.props.setNavData({ interface: iFace, site: this.state.site, interfaces: this.state.interfaces, userTypes: this.state.userTypes });

    setTimeout(() => {
      if (!iFace) {
        this.props.history.push(`/tvpicker`);
      } else {
        this.props.history.push(`/tvpicker/${encodeURIComponent(iFace.Id)}`);
      }
    }, 50);
  };

  saveEditInterface = () => {
    if (_.isEmpty(this.state.interfaceTitleInput)) {
      return;
    }
    this.setState({
      savingInterface: true,
    });
    const promises = [];
    if (this.state.interfaceTitleInput !== this.state.editingInterface.Title) {
      // update interface title
      promises.push(
        typeActions.saveInterface(
          this.props.auth.site,
          undefined,
          this.state.interfaceTitleInput,
          undefined,
          this.state.editingInterface.Id,
        ),
      );
    }

    Promise.all(promises)
      .then((res) => {
        this.refreshInterfaces();
        this.setState({
          savingInterface: false,
          editingInterface: null,
        });
      })
      .catch((err) => {
        this.refreshInterfaces();
        this.setState({
          savingInterface: false,
          editingInterface: null,
        });
      });
  };

  renderHeader() {
    return (
      <div className="featurePicker_headerTray">
        <FontAwesome className="featurePicker_back" name="angle-left" onClick={this.handleCancel} />
        <Text type="formTitleLarge" className="featurePicker_pageTitle">
          Configure TV
        </Text>
        <div className="flex flex-1 flex-center flex-reverse">
          <Button inline buttonType="primary" onClick={this.handleSubmit} isActive={this.validateForm()}>
            Save View
          </Button>
        </div>
      </div>
    );
  }

  renderWidgetPreview(widget, type) {
    return (
      <div className={`tvPickerPreview_widget tvPickerPreview_widget-${widget.key}-${type}`} style={{ backgroundColor: this.state.colour }}>
        <p className="tvPickerPreview_widget_title" style={{ color: this.state.colour }}>
          {widget.title}
        </p>
      </div>
    );
  }

  renderPreviewContent() {
    if (this.state.isPortrait) {
      return (
        <div className="tvPickerPreview_inner_content">
          {this.state.selectedWidgets.map((w) => {
            return (
              <div className="flex-1 relative" key={w.key}>
                {this.renderWidgetPreview(w, `p${this.state.selectedWidgets.length}`)}
              </div>
            );
          })}
        </div>
      );
    }
    switch (this.state.selectedWidgets.length) {
      case 1:
        return <div className="tvPickerPreview_inner_content">{this.renderWidgetPreview(this.state.selectedWidgets[0], 'full')}</div>;
      case 2:
        return (
          <div className="tvPickerPreview_inner_content flex">
            <div className="flex-1 relative">{this.renderWidgetPreview(this.state.selectedWidgets[0], 'hv')}</div>
            <div className="flex-1 relative">{this.renderWidgetPreview(this.state.selectedWidgets[1], 'hv')}</div>
          </div>
        );
      case 3:
        return (
          <div className="tvPickerPreview_inner_content flex flex-column">
            <div className="flex-1 relative flex">
              <div className="flex-1 relative">{this.renderWidgetPreview(this.state.selectedWidgets[0], 'q')}</div>
              <div className="flex-1 relative">{this.renderWidgetPreview(this.state.selectedWidgets[1], 'q')}</div>
            </div>
            <div className="flex-1 relative">{this.renderWidgetPreview(this.state.selectedWidgets[2], 'hh')}</div>
          </div>
        );
      case 4:
        return (
          <div className="tvPickerPreview_inner_content flex flex-column">
            <div className="flex-1 relative flex">
              <div className="flex-1 relative">{this.renderWidgetPreview(this.state.selectedWidgets[0], 'q')}</div>
              <div className="flex-1 relative">{this.renderWidgetPreview(this.state.selectedWidgets[1], 'q')}</div>
            </div>
            <div className="flex-1 relative flex">
              <div className="flex-1 relative">{this.renderWidgetPreview(this.state.selectedWidgets[2], 'q')}</div>
              <div className="flex-1 relative">{this.renderWidgetPreview(this.state.selectedWidgets[3], 'q')}</div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  renderPortraitPreview() {
    let content = <div className="tvPickerPreview_inner"></div>;
    if (this.state.site) {
      content = (
        <div className="tvPickerPreview_inner">
          <div className="tvPickerPreview_header">
            {this.state.logo ? (
              <div className="tvPickerPreview_header_logoContainer">
                <div className="tvPickerPreview_header_logo" style={{ backgroundImage: `url('${this.state.logo}')` }} />
              </div>
            ) : (
              <p className="tvPickerPreview_header_text" style={{ color: this.state.gradientHeader ? '#fff' : this.state.colour }}>
                {this.state.site.siteName}
              </p>
            )}
          </div>
          <div className="tvPickerPreview_weather">
            <p className="tvPickerPreview_weather_welcome">Welcome to&nbsp;{this.state.site.siteName}</p>
          </div>
          {this.renderPreviewContent()}
        </div>
      );
    }
    return (
      <div className={`tvPickerPreview tvPickerPreview-portrait ${this.state.flippedPortrait ? 'tvPickerPreview-portrait-rotated' : ''}`}>
        {content}
      </div>
    );
  }

  renderPreview() {
    if (this.state.isPortrait) {
      return this.renderPortraitPreview();
    }
    let content = <div className="tvPickerPreview_inner"></div>;
    if (this.state.site) {
      content = (
        <div className="tvPickerPreview_inner">
          <div className="tvPickerPreview_header">
            {this.state.logo ? (
              <div className="tvPickerPreview_header_logoContainer">
                <div className="tvPickerPreview_header_logo" style={{ backgroundImage: `url('${this.state.logo}')` }} />
              </div>
            ) : (
              <p className="tvPickerPreview_header_text" style={{ color: this.state.gradientHeader ? '#fff' : this.state.colour }}>
                {this.state.site.siteName}
              </p>
            )}
          </div>
          <div className="tvPickerPreview_weather">
            <p className="tvPickerPreview_weather_welcome">Welcome to&nbsp;{this.state.site.siteName}</p>
          </div>
          {this.renderPreviewContent()}
        </div>
      );
    }
    return <div className="tvPickerPreview">{content}</div>;
  }

  renderWidgetTitle(widget, index, isSelected) {
    const isEditing = this.state.isEditingWidgetTitle === widget.key;
    return (
      <div className="featurePicker_mainSection_tile_footer_inner">
        {!isEditing && (
          <div className="featurePicker_mainSection_tile_title">
            <p className="featurePicker_mainSection_tile_footer_text">{widget.title}</p>
            {isSelected && (
              <FontAwesome
                name="pencil"
                className="featurePicker_mainSection_tile_footer_edit"
                onClick={() => {
                  this.startEditingWidgetTitle(widget.key);
                }}
              />
            )}
          </div>
        )}
        {isSelected && isEditing && (
          <div className="flex-1 flex-reverse">
            <Button inline compact buttonType="primary" onClick={this.endEditingWidgetTitle} isActive={true}>
              Save
            </Button>
            <div className="flex-1 paddingRight-10">
              <GenericInput
                inputClass="featurePicker_mainSection_tile_footer_text"
                inputStyle={{ padding: 0 }}
                style={{ marginBottom: 0 }}
                textWrapperStyle={{ marginBottom: 0 }}
                id={`${widget.key}_input`}
                type="text"
                value={widget.title}
                onChange={(e) => this.handleWidgetTitleChange(widget, e.target.value)}
                maxLength={20}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  renderWidget(widget, index, isSelected) {
    let classes = `featurePicker_mainSection_tile featurePicker_mainSection_tile-${widget.key}TVWidget featurePicker_mainSection_tile-tv`;
    if (_.isEmpty(this.state.selectedWidgets) || isSelected) {
      classes += ' featurePicker_mainSection_tile-condensed';
    } else {
      classes += ' featurePicker_mainSection_tile-compressed';
    }
    let reorderActions = [];

    // if (isEditing) {
    //   classes += ' featurePicker_mainSection_tile-isEditing';
    // }
    if (isSelected) {
      classes += ' featurePicker_mainSection_tile-selected';

      reorderActions = this.getReorderActions(index);
    }
    return (
      <div
        className={classes}
        onClick={() => {
          this.selectWidget(widget);
        }}
        key={widget.key}
      >
        <div className="featurePicker_mainSection_tile_inner">
          <div className="featurePicker_mainSection_tile_footer">
            <div className="featurePicker_mainSection_tile_footer_inner">
              {this.renderWidgetTitle(widget, index, isSelected)}
              <span className="featurePicker_mainSection_tile_addIcon">+</span>
              {/* <FontAwesome name="plus" className="featurePicker_mainSection_tile_addIcon" /> */}
            </div>
          </div>
          <div className="featurePicker_mainSection_tile_image_inner"></div>
          <div
            className="featurePicker_mainSection_tile_image_cancelButton"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.deselectWidget(widget);
            }}
          >
            <SVGIcon colour={COLOUR_DUSK_LIGHTER} icon="close" className="featurePicker_mainSection_tile_image_cancelButton_icon" />
          </div>
        </div>
        <div className="featurePicker_mainSection_tile_reorder">
          {reorderActions.map((a) => {
            return (
              <div
                className="featurePicker_mainSection_tile_reorder_button"
                onClick={() => {
                  this.moveWidget(index, a.change);
                }}
                key={a.icon}
              >
                <FontAwesome name={`angle-${a.icon}`} className="featurePicker_mainSection_tile_reorder_icon" />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderSelectedWidgets() {
    return this.state.selectedWidgets.map((w, i) => {
      return this.renderWidget(w, i, true);
    });
  }

  renderSelectableWidgets() {
    const selectableWidgets = _.filter(this.widgets, (w) => {
      return !_.some(this.state.selectedWidgets, (iw) => {
        return iw.key === w.key;
      });
    });
    if (_.isEmpty(selectableWidgets)) {
      return null;
    }
    const content = selectableWidgets.map((w, i) => {
      return this.renderWidget(w, i, false);
    });
    if (!_.isEmpty(this.state.selectedWidgets)) {
      return (
        <div className="featurePicker_mainSection_widgetContainer featurePicker_mainSection_widgetContainer-boxed featurePicker_mainSection_widgetContainer-boxed-tv">
          <p className="featurePicker_mainSection_widgetContainer_title">
            You can add any additional content <span className="featurePicker_mainSection_widgetContainer_title-optional">(optional)</span>
          </p>
          {content}
        </div>
      );
    }
    return <div>{content}</div>;
  }

  renderPicker() {
    if (!this.state.loadedInterface || !this.state.site) {
      return null;
    }
    return (
      <div>
        {/* <p className="pageSubtitle" style={{ marginBottom: 20 }}>
          TV Features
        </p> */}
        <Text type="formTitleMedium">Select your features</Text>
        <div className="featurePicker_mainSection_widgetContainer featurePicker_mainSection_widgetContainer-boxed featurePicker_mainSection_widgetContainer-boxed-tv">
          {this.renderSelectedWidgets()}
        </div>

        {this.renderSelectableWidgets()}
      </div>
    );
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button inline buttonType="tertiary" buttonStyle={{ marginRight: 20 }} onClick={this.handleCancel} isActive>
          Cancel
        </Button>
        <Button inline buttonType="primary" onClick={this.handleSubmit} isActive={this.validateForm()}>
          Save View
        </Button>
      </div>
    );
  }

  renderInterfaceButton(iFace) {
    return (
      <div
        key={iFace.Id}
        className={`featurePickerInterfaces_button ${
          (iFace && iFace.Id === this.state.interfaceId) || (!iFace && _.isEmpty(this.state.interfaceId))
            ? 'featurePickerInterfaces_button-active'
            : ''
        }`}
        onClick={() => {
          this.selectInterface(iFace);
        }}
      >
        <p className="featurePickerInterfaces_button_text">{iFace.Title}</p>
        <div
          className="featurePickerInterfaces_button_edit"
          onClick={() => {
            this.editInterface(iFace);
          }}
        >
          Edit
        </div>
      </div>
    );
  }

  renderInterfaces() {
    return (
      <div className="featurePickerInterfaces">
        <FontAwesome name="plus-circle" className="featurePickerInterfaces_add" onClick={this.openAddInterface}></FontAwesome>
        {this.getTVInterfaces().map((iFace) => {
          return this.renderInterfaceButton(iFace);
        })}
        <p className="featurePickerInterfaces_title">
          TV
          <br />
          <span className="featurePickerInterfaces_title-sub">VIEWS</span>
        </p>
      </div>
    );
  }

  renderInterfacePopup() {
    if (!this.state.editingInterface) {
      return null;
    }
    if (this.state.savingInterface) {
      return (
        <Popup title={`Saving changes`} maxWidth={600} hasPadding>
          <div className="flex flex-center">
            <FontAwesome className="spinner" name="spinner fa-pulse fa-fw" />
          </div>
        </Popup>
      );
    }
    if (this.state.showConfirmInterfaceDelete) {
      return (
        <Popup
          title={`Are you sure?`}
          noDotTitle
          maxWidth={600}
          hasPadding
          buttons={[
            {
              type: 'outlined',
              onClick: this.cancelDeleteInterface,
              isActive: true,
              text: 'Cancel',
              // className: 'button--signup',
            },
            {
              type: 'primary',
              onClick: this.confirmDeleteInterface,
              isActive: !_.isEmpty(this.state.editingInterface.Title),
              text: 'Delete',
              // className: 'button--signup',
            },
          ]}
        ></Popup>
      );
    }
    return (
      <Popup
        title={`Edit ${this.state.editingInterface.Title}`}
        maxWidth={600}
        hasPadding
        buttons={[
          {
            type: 'outlined',
            onClick: this.goToDeleteInterface,
            isActive: true,
            text: 'Delete View',
            // className: 'button--signup',
          },
          {
            type: 'primary',
            onClick: this.saveEditInterface,
            isActive: !_.isEmpty(this.state.interfaceTitleInput),
            text: 'Done',
            // className: 'button--signup',
          },
        ]}
      >
        <GenericInput
          id="interfaceTitleInput"
          type="text"
          value={this.state.interfaceTitleInput}
          onChange={(e) => this.handleChange(e)}
          maxLength={20}
        />
      </Popup>
    );
  }

  renderAddInterfacePopup() {
    if (!this.state.showAddInterface) {
      return null;
    }
    if (this.state.savingInterface) {
      return (
        <Popup title={`Saving changes`} maxWidth={600} hasPadding>
          <div className="flex flex-center">
            <FontAwesome className="spinner" name="spinner fa-pulse fa-fw" />
          </div>
        </Popup>
      );
    }
    return (
      <Popup
        title={`Create New View`}
        maxWidth={600}
        hasPadding
        buttons={[
          {
            type: 'outlined',
            onClick: this.closeAddInterface,
            isActive: true,
            text: 'Cancel',
            // className: 'button--signup',
          },
          {
            type: 'primary',
            onClick: this.saveNewInterface,
            isActive: !_.isEmpty(this.state.interfaceTitleInput),
            text: 'Done',
            // className: 'button--signup',
          },
        ]}
      >
        <p className="interfacePopup_helperBox">
          A view is a different experience within the same community app. This will be used to differentiate TV displays based on your
          configured settings.
        </p>
        <GenericInput
          id="interfaceTitleInput"
          type="text"
          value={this.state.interfaceTitleInput}
          onChange={(e) => this.handleChange(e)}
          maxLength={20}
          placeholder="Enter view name"
        />
      </Popup>
    );
  }

  renderSuccessPopup() {
    if (!this.state.success) {
      return null;
    }
    return (
      <Popup
        title="Saved"
        subtitle="Your TV View is now ready!"
        hasPadding
        minWidth={400}
        minHeight={200}
        buttons={[
          {
            type: 'primary',
            onClick: this.closeSuccessPopup,
            isActive: true,
            text: 'Done',
          },
        ]}
      />
    );
  }

  renderPortraitToggle() {
    return (
      <div className="">
        <div className="tvPicker_portraitToggle">
          <Switch
            className="tvPicker_portraitToggle_toggle"
            checked={this.state.isPortrait}
            onChange={this.onTogglePortrait}
            onColor={COLOUR_BRANDING_MAIN}
            offColor={COLOUR_DUSK_LIGHTER}
            height={24}
            width={40}
            checkedIcon={false}
            uncheckedIcon={false}
          />
          <p className="tvPicker_portraitToggle_label">Portrait Mode</p>
        </div>

        {!!this.state.isPortrait && (
          <div className="tvPicker_portraitToggle marginTop-16">
            <Switch
              className="tvPicker_portraitToggle_toggle"
              checked={this.state.flippedPortrait}
              onChange={this.onToggleFlipPortrait}
              onColor={COLOUR_BRANDING_MAIN}
              offColor={COLOUR_DUSK_LIGHTER}
              height={24}
              width={40}
              checkedIcon={false}
              uncheckedIcon={false}
            />
            <p className="tvPicker_portraitToggle_label">Bottom of TV on the left</p>
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="pageContainer relative flex flex-column">
        <OnboardingStep step="intro_fp_tv_1" />
        {this.renderInterfacePopup()}
        {this.renderAddInterfacePopup()}
        {this.renderSuccessPopup()}
        {this.renderHeader()}
        <div
          style={{
            paddingTop: 20,
            paddingLeft: 20,
            flex: 1,
            flexGrow: 1,
            overflowY: 'auto',
            visibility: this.state.loading ? 'hidden' : 'visible',
          }}
        >
          {this.renderInterfaces()}
          <div className="tvPicker" style={{ paddingLeft: 20 }}>
            <div className="tvPickerLeft">
              {this.renderPreview()}
              {this.renderPortraitToggle()}
            </div>
            <div className="tvPickerRight">{this.renderPicker()}</div>
          </div>
        </div>
        {/* <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth, navData: state.nav };
};

export default connect(mapStateToProps, { setNavData, refreshAuthUser })(withRouter(TVPicker));
