import { extensionScreens } from '../config/features';

import Dashboard from '../screens/Menus/Dashboard';
import Profile from '../screens/Menus/Profile';

import AndroidDownload from '../screens/DangerZone/Downloads/AndroidDownload';
import TestflightDownload from '../screens/DangerZone/Downloads/TestflightDownload';

// import UserImport from '../screens/Users/UserImport';
import AddUser from '../screens/Users/AddUser';
import AddKiosk from '../screens/Users/AddKiosk';
import Kiosk from '../screens/Users/Kiosk';
import BulkImport from '../screens/Users/BulkImport';
import BulkUploads from '../screens/Users/BulkUploads';
import Users from '../screens/Users/Users';
import User from '../screens/Users/User';
import LinkUsers from '../screens/Users/LinkUsers/LinkUsers';

import AddEvent from '../screens/Events/AddEvent';
import ListEvents from '../screens/Events/ListEvents';
import EventReport from '../screens/Events/EventReport';
import EventSubmissions from '../screens/Events/EventSubmissions';
import EventSubmission from '../screens/Events/EventSubmission';
import AddEventsByJson from '../screens/Events/AddEventsByJson';
import ScrapeEventPage from '../screens/Events/ScrapeEventPage';
import EventAnalytics from '../screens/Events/EventAnalytics';

import SetFeedbackEmail from '../screens/Feedback/SetFeedbackEmail';
import Feedback from '../screens/Feedback/ListFeedback';

import Login from '../screens/Login/Login';
import Logout from '../screens/Login/Logout';
import Splash from '../screens/Login/Splash';

import VisitorList from '../screens/Visitors/VisitorList';
import ContractorList from '../screens/Visitors/ContractorList';
import ContractorTerms from '../screens/Visitors/ContractorTerms';
import EventFeedback from '../screens/Events/EventFeedback';
import EditTerms from '../screens/Visitors/EditTerms';
import ImportantContacts from '../screens/ImportantContacts/ImportantContacts';
import AddImportantContact from '../screens/ImportantContacts/AddImportantContact';
import EditAppTerms from '../screens/Terms/EditAppTerms';
import AddMap from '../screens/Maps/AddMap';
import Maps from '../screens/Maps/Maps';
import AddInfoPage from '../screens/Info/AddInfoPage';
import AddWelcomePage from '../screens/Info/AddWelcomePage';
import WelcomePagesPage from '../screens/Info/WelcomePagesPage';
import InfoHub from '../screens/Info/InfoHub';
import AddKey from '../screens/Keys/AddKey';
import Keys from '../screens/Keys/Keys';
import KeyHistory from '../screens/Keys/KeyHistory';

import AddOffer from '../screens/Marketplace/AddOffer';
import OffersHub from '../screens/Marketplace/OffersHub';

import EditPermissions from '../screens/DangerZone/Permissions/EditPermissions';
import EditSites from '../screens/DangerZone/Sites/EditSites';
import UserDisable from '../screens/DangerZone/UserDisable/UserDisable';

import AppStoreVersions from '../screens/DangerZone/Versions/AppStoreVersions';

import AlertsHub from '../screens/Alerts/AlertsHub';
import AddAlert from '../screens/Alerts/AddAlert';
import Alert from '../screens/Alerts/Alert';

import PollsHub from '../screens/Polls/PollsHub';
import AddPoll from '../screens/Polls/AddPoll';
import PollResults from '../screens/Polls/PollResults';

import AddDish from '../screens/Food/AddDish';

import AddService from '../screens/Services/AddService';
import ServiceAnalytics from '../screens/Services/ServiceAnalytics';

import SupportHub from '../screens/Support/SupportHub';
import AddTicket from '../screens/Support/AddTicket';
import Ticket from '../screens/Support/Ticket';
import Upvoty from '../screens/Support/Upvoty';

// New Section Hubs
import EventsHub from '../screens/Events/EventsHub';
import FoodHub from '../screens/Food/FoodHub';
import FacilityHub from '../screens/Facilities/FacilityHub';
import ServicesHub from '../screens/Services/ServicesHub';
import SponsorsHub from '../screens/Sponsors/SponsorsHub';
import SignInHub from '../screens/Visitors/SignInHub';
import UsersHub from '../screens/Users/UsersHub';
import SettingsHub from '../screens/DangerZone/SettingsHub';
import PermissionsPage from '../screens/DangerZone/Permissions/PermissionsPage';
import InviteCodesPage from '../screens/DangerZone/InviteCodes/InviteCodesPage';
import AddInviteCode from '../screens/DangerZone/InviteCodes/AddInviteCode';
import FeaturePicker from '../screens/DangerZone/Sites/FeaturePicker';
import SiteSignUp from '../screens/DangerZone/Sites/SiteSignUp';
import SiteSignUpComplete from '../screens/DangerZone/Sites/SiteSignUpComplete';

import SignInForm from '../screens/Visitors/SignInForm';
import SignInFormPreview from '../screens/Visitors/SignInFormPreview';

import TVPicker from '../screens/TV/TVPicker';
import TV from '../screens/TV/TV';
import ActivateTV from '../screens/TV/ActivateTV';

import AddCardCommBank from '../screens/Payment/AddCardCommBank';
import AddCardStripe from '../screens/Payment/AddCardStripe';
import Billing from '../screens/Payment/Billing';
import Payment from '../screens/Payment/Payment';

import ImageLibraryHub from '../screens/ImageLibrary/ImageLibraryHub';

import AddFacility from '../screens/Facilities/AddFacility';
import FacilityAnalytics from '../screens/Facilities/FacilityAnalytics';

import AddForm from '../screens/Forms/AddForm';

import AutomationHub from '../screens/Automation/AutomationHub';
import AWSAccount from '../screens/Automation/AWSAccount';
import AWSSetup from '../screens/Automation/AWSSetup';
import Repository from '../screens/Automation/Repository';
import AppSetupiOS from '../screens/Automation/AppSetupiOS';
import AppSetupAndroid from '../screens/Automation/AppSetupAndroid';
import Deployment from '../screens/Automation/Deployment';

import AppStoreAssets from '../screens/Master/AppStoreAssets';
import AppAssets from '../screens/Master/AppAssets';
import MasterMenu from '../screens/Master/MasterMenu';

import CommentsDashboard from '../screens/Comments/CommentsDashboard';
import Thread from '../screens/Comments/Thread';

export const pages = {
  ...extensionScreens,
  Dashboard,
  Profile,
  TV,
  ActivateTV,
  AndroidDownload,
  TestflightDownload,
  AddKiosk,
  Kiosk,
  AddUser,
  BulkImport,
  BulkUploads,
  Users,
  User,
  LinkUsers,
  AddEvent,
  ListEvents,
  EventReport,
  EventAnalytics,
  AddEventsByJson,
  ScrapeEventPage,
  SetFeedbackEmail,
  Feedback,
  Login,
  Logout,
  Splash,
  VisitorList,
  ContractorList,
  ContractorTerms,
  EventFeedback,
  EditTerms,
  ImportantContacts,
  AddImportantContact,
  EditAppTerms,
  AddMap,
  Maps,
  OffersHub,
  AddOffer,
  AddInfoPage,
  AddWelcomePage,
  WelcomePagesPage,
  InfoHub,
  AddKey,
  Keys,
  KeyHistory,
  EditPermissions,
  EditSites,
  UserDisable,
  AppStoreVersions,
  EventSubmissions,
  EventSubmission,
  AddService,
  ServiceAnalytics,
  AddAlert,
  Alert,
  PollsHub,
  AddPoll,
  PollResults,
  AlertsHub,
  EventsHub,
  FacilityHub,
  ServicesHub,
  SponsorsHub,
  SignInHub,
  UsersHub,
  SettingsHub,
  PermissionsPage,
  InviteCodesPage,
  AddInviteCode,
  AddCardCommBank,
  AddCardStripe,
  Billing,
  SupportHub,
  AddTicket,
  Ticket,
  Upvoty,
  FeaturePicker,
  TVPicker,
  SiteSignUp,
  SiteSignUpComplete,
  ImageLibraryHub,
  AddFacility,
  FacilityAnalytics,
  FoodHub,
  AddDish,
  SignInForm,
  SignInFormPreview,
  AddForm,
  AutomationHub,
  AWSAccount,
  AWSSetup,
  Repository,
  AppSetupiOS,
  AppSetupAndroid,
  Deployment,
  Payment,
  AppStoreAssets,
  AppAssets,
  MasterMenu,
  CommentsDashboard,
  Thread,
};
