import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import Cookies from 'js-cookie';
import {
  baseUploadsUrl,
  baseLibraryUrl,
  defaultProfileImage,
  logo,
  baseStage,
  baseClient,
  contentSource,
  contentSourceSite,
  CONST_STRINGS,
} from '../config';
import { extensionFeatureInfo, extensionAliases } from '../config/features';

export const arrayToObject = (array, key) => {
  array.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});
};

export const mergeLists = (l1, l2, key) => {
  // console.log('mL');
  // console.log(l1);
  // console.log(l2);
  // console.log(key);
  // var indexed = arrayToObject(l2, key);
  // console.log('indexed');
  // return _.map(l1, function(item){
  //     return indexed[item[key]] ? indexed[item[key]] : item;
  // });
};

export const getLogo = (siteBranding) => {
  if (siteBranding && siteBranding.Logo) {
    return siteBranding.Logo;
  }
  return logo;
};

export const safeReadParams = (props, key) => {
  if (props && props.match && props.match.params) {
    return props.match.params[key];
  }
  return null;
};

export const getUrlParams = () => {
  var vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
};

export const isEmail = (email) => {
  if (_.isEmpty(email)) {
    return false;
  }
  const atpos = email.indexOf('@');
  const dotpos = email.lastIndexOf('.');
  if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= email.length) {
    return false;
  }
  return true;
};

export const isUrl = (url) => {
  const dotpos = url.lastIndexOf('.');
  if (dotpos < 2 || dotpos + 2 >= url.length) {
    return false;
  }
  return true;
};

export const randomString = () => {
  return 'xxxxxxxx4xxxyxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const generateImageName = (name) => {
  return `xxxxxxxx4xxxyxxxxxxxxxxxxx${name}`.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const capitalize = (string) => {
  if (_.isEmpty(string)) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getFirstName = (string) => {
  if (_.isEmpty(string)) {
    return '';
  }
  return string.split(' ')[0];
};

export const getExtension = (url) => {
  if (!url) {
    return null;
  }
  const fileSplit = url.split('.');
  return fileSplit[fileSplit.length - 1].toLowerCase();
};

export const getFileName = (url, noExtension) => {
  if (!url) {
    return null;
  }
  const fileSplit = url.split('/');
  const name = fileSplit[fileSplit.length - 1].toLowerCase();
  if (!noExtension) {
    return name;
  }
  return name.split('.')[0];
};

export const removeWhitespace = (input) => {
  if (!input) {
    return input;
  }
  return input.replace(/ /g, '');
};

export const onlyAlphanumeric = (input) => {
  if (!input) {
    return input;
  }
  return input.replace(/[^a-z0-9]/gi, '');
};

export const get1400 = (url) => {
  if (!url) {
    return url;
  }
  if (url.indexOf('https://plussprdstorage.blob.core.windows.net/') !== -1) {
    return url.replace('https://plussprdstorage.blob.core.windows.net/', 'https://plusscdn.azureedge.net/');
  }
  if (url.indexOf('https://plusscdn.azureedge.net/') !== -1) {
    return url.replace('/uploads/', '/uploads1400/').replace('/general/', '/general1400/');
  }
  if (url.indexOf(baseUploadsUrl) !== -1 || url.indexOf(baseLibraryUrl) !== -1) {
    const extension = getExtension(url);
    let urlToUse = url;
    if (extension !== 'jpg') {
      urlToUse = `${url.substring(0, url.length - (extension.length + 1))}.jpg`;
    }
    return urlToUse.replace('/uploads/', '/1400/');
  }
  return url;
};

export const get300 = (url) => {
  if (!url) {
    return url;
  }
  if (url.indexOf('https://plussprdstorage.blob.core.windows.net/') !== -1) {
    return url.replace('https://plussprdstorage.blob.core.windows.net/', 'https://plusscdn.azureedge.net/');
  }
  if (url.indexOf('https://plusscdn.azureedge.net/') !== -1) {
    return url.replace('/uploads/', '/uploads300/').replace('/general/', '/general300/');
  }
  if (url.indexOf(baseUploadsUrl) !== -1 || url.indexOf(baseLibraryUrl) !== -1) {
    const extension = getExtension(url);
    let urlToUse = url;
    if (extension !== 'jpg') {
      urlToUse = `${url.substring(0, url.length - (extension.length + 1))}.jpg`;
    }
    return urlToUse.replace('/uploads/', '/300/');
  }
  return url;
};

export const getThumb300 = (url) => {
  if (!url) {
    return url;
  }
  if (url.indexOf('https://plussprdstorage.blob.core.windows.net/') !== -1) {
    return url.replace('https://plussprdstorage.blob.core.windows.net/', 'https://plusscdn.azureedge.net/');
  }
  if (url.indexOf('https://plusscdn.azureedge.net/') !== -1) {
    return url
      .replace('/uploads/', '/uploads-thumb/')
      .replace('/general/', '/general300/')
      .replace('/uploads1400/', '/uploads-thumb/')
      .replace('/general1400/', '/general300/');
  }
  if (url.indexOf(baseUploadsUrl) !== -1) {
    const extension = getExtension(url);
    let urlToUse = url;
    if (extension !== 'jpg') {
      urlToUse = `${url.substring(0, url.length - (extension.length + 1))}.jpg`;
    }
    return urlToUse.replace('/1400/', '/thumb300/').replace('/uploads/', '/thumb300/');
  }
  return url;
};

export const getCompressed = (url) => {
  if (!url || typeof url !== 'string') return '';
  return url.replace('/uploads/', '/compressed/');
};

export const isVideo = (url) => {
  const extension = getExtension(url);
  return ['mov', 'mp4'].includes(extension);
};

export const getTimepickerTime = (input) => {
  var timeSplit = input.split(':');
  if (timeSplit[1].indexOf('pm') > -1) {
    if (timeSplit[0] !== '12') {
      timeSplit[0] = (parseInt(timeSplit[0], 10) + 12).toString();
    }
  }
  if (timeSplit[1].indexOf('am') > -1) {
    if (timeSplit[0] === '12') {
      timeSplit[0] = '00';
    }
  }
  return {
    hours: timeSplit[0],
    minutes: timeSplit[1].substring(0, 2),
  };
};

export const getDateFromTimeDatePickers = (dateInput, timeInput) => {
  const dateObject = new Date(dateInput);
  const time = getTimepickerTime(timeInput);
  dateObject.setHours(time.hours);
  dateObject.setMinutes(time.minutes);
  return dateObject;
};

export const getUTCFromTimeDatePickers = (dateInput, timeInput) => {
  const dateObject = getDateFromTimeDatePickers(dateInput, timeInput);
  return moment.utc(dateObject);
};

export const getPluralS = (count) => {
  if (count === 1) {
    return '';
  }
  return 's';
};

export const getPluralOptions = (count, singular, plural) => {
  if (count === 1) {
    return singular;
  }
  return plural;
};

export const getPercentage = (count, total) => {
  if (!count) {
    return '0%';
  }
  if (!total) {
    return '100%';
  }
  return `${Math.round((100 * count) / total)}%`;
};

export const getUserPreview = (user) => {
  return {
    displayName: user.displayName,
    id: user.Id,
    profilePic: user.profilePic || defaultProfileImage,
  };
};

export const usersToPreviews = (users) => {
  return _.map(users, getUserPreview);
};

export const toParagraphed = (text, style) => {
  if (!text) {
    return text;
  }
  text = text.replace(/\t/g, '\u00a0\u00a0\u00a0\u00a0');
  const textSplit = text.split('\n');
  return _.map(textSplit, (t, i) => {
    return (
      <span key={`paragraph_${i}`} style={{ display: 'block', ...style }}>
        {t || '\u00A0'}
      </span>
    );
  });
};

export const getSiteNameFromRoles = (site, roles) => {
  return getSiteName(
    site,
    roles.map((r) => {
      return r.siteInfo;
    }),
  );
};

export const getSiteName = (site, sites) => {
  if (site === 'hq') {
    return 'HQ';
  }
  const siteObject = _.find(sites, (t) => {
    return t.Id === site;
  });
  if (siteObject) {
    return siteObject.siteName;
  }
  return site;
};

export const formatCurrency = (number, locale = 'en-AU', curencyCode = 'AUD', shouldFix = false) => {
  const formatter = Intl.NumberFormat(locale, { style: 'currency', currency: curencyCode, minimumFractionDigits: 2 });
  let formattedNumber = number;
  if (shouldFix && formatter.format(formattedNumber).indexOf('NaN') > -1) {
    formattedNumber = number.replace(/[^\d.-]/g, '');
  }
  return formatter.format(formattedNumber);
};

export const currencyToFloat = (number) => {
  let result = parseFloat(number);
  if (isNaN(result)) {
    result = parseFloat(number.replace(/[^\d.-]/g, ''));
    if (isNaN(result)) {
      result = 0;
    }
  }
  return result;
};

export const getCurrencySymbol = () => {
  return formatCurrency(0).replace(/[0-9.]+/g, '');
};

export const isCurrency = (text) => {
  if (_.isNil(text) || text === '') return false;
  const price = parseFloat(typeof text === 'string' ? text.replace(getCurrencySymbol(), '') : text, 10);
  // console.log('isCurrency', _.isNumber(price), !Number.isNaN(price), price);
  return _.isNumber(price) && !Number.isNaN(price) ? price : false;
};

export const isPaidItem = (enabled, tickets) => {
  // console.log('isPaidItem', enabled, tickets);
  return (
    enabled &&
    tickets &&
    tickets.merchant &&
    tickets.categories &&
    tickets.categories.length > 0 &&
    tickets.categories.every((t) => !t.price || _.isNumber(t.price))
  );
};

export const getTotalQuantity = (tickets) => _.sumBy(tickets, (t) => t.quantity || 0);

export const getTotalPrice = (tickets) => _.sumBy(tickets, (t) => t.price * (t.quantity || 0));

export const parseTickets = (tickets, merchant) => {
  if (Array.isArray(tickets)) return { merchant, categories: tickets };
  if (tickets && typeof tickets === 'object') {
    // Clean up invalid categories
    const newTickets = _.cloneDeep(tickets);
    newTickets.categories = newTickets.categories.filter((c) => isTicketPriceValid(c));
    return newTickets;
  }
  return { merchant, categories: [{ category: 'Default', price: tickets }] };
};

export const compileTickets = (tickets, merchant) => {
  const newTickets = _.cloneDeep(tickets);
  newTickets.merchant = merchant;
  newTickets.categories = newTickets.categories.map((ticket) => {
    ticket.price = isCurrency(ticket.price);
    return ticket;
  });
  return newTickets;
};

export const getTicketPriceText = (ticket) => {
  return typeof ticket.price === 'string' ? ticket.price : formatCurrency(ticket.price);
};

export const isTicketCategoryValid = (ticket, ticketDetail) => {
  return !_.isEmpty(ticket.category) && ticketDetail.categories.filter((t) => t.category === ticket.category).length === 1;
};

export const isTicketPriceValid = (ticket) => {
  // console.log('isTicketPriceValid', ticket.price, isCurrency(ticket.price) !== false);
  return isCurrency(ticket.price) !== false;
};

export const isTicketsValid = (tickets) => {
  if (tickets && tickets.categories && tickets.categories.length > 0) {
    return tickets.categories.every((ticket) => isTicketCategoryValid(ticket, tickets) && isTicketPriceValid(ticket));
  }
  return true;
};

export const getFee = (feeInfo, amount) => {
  if (!feeInfo.fee) return 0;
  return feeInfo.isPercentage ? (feeInfo.fee / 100) * amount : feeInfo.fee;
};

export const isRefund = (transaction) => transaction.TransactionType === 'REFUND';

export const combineTickets = (ticketsArray, diff = false) => {
  if (_.isNil(ticketsArray) || ticketsArray.length === 0) return [];

  const combined = ticketsArray.reduce((a, c) => {
    if (_.isNil(a) || a.length === 0) return c;
    const summed = a.map((cat) => {
      const cloned = _.clone(cat);
      const match = c.find((i) => i.category === cloned.category);
      cloned.quantity = (diff ? -1 : 1) * (cloned.quantity || 0) + (match ? match.quantity || 0 : 0);
      return cloned;
    });
    return summed;
  });
  return combined;
};

export const combineTransactions = (transactionsArray) => {
  if (_.isNil(transactionsArray) || transactionsArray.length === 0) return null;

  const combined = _.orderBy(transactionsArray, 'UnixTimestamp', 'desc').reduce((a, c) => {
    // console.log('transaction a', a.TransactionType, a.TransactionId, a.Amount, a.Fee.amount);
    // console.log('transaction c', c.TransactionType, c.TransactionId, c.Amount, c.Fee.amount);
    const cloned = _.cloneDeep(c);
    cloned.Amount = cloned.Amount + a.Amount;
    cloned.Fee.amount = cloned.Fee.amount + a.Fee.amount;
    cloned.Item.categories = combineTickets([cloned.Item.categories, a.Item.categories]);
    return cloned;
  });
  // console.log('combined', combined.TransactionType, combined.TransactionId, combined.Amount, combined.Fee.amount);
  return combined;
};

export const consolidateTransactions = (transactionsArray) => {
  const transactions = transactionsArray
    .filter((pt) => pt.TransactionType === 'PAYMENT')
    .map((p) => {
      // Combine with all refunds for the transaction
      const refunds = transactionsArray.filter((rt) => rt.TransactionType === 'REFUND' && rt.TransactionId === p.TransactionId);
      return combineTransactions([...refunds, p]);
    })
    .filter((p) => getTotalQuantity(p.Item.categories) > 0);
  return transactions;
};

export const isKiosk = (type) => {
  return type === 'KIOSK' || type === 'FORMKIOSK' || type === 'SIGNINKIOSK';
};

export const isContentSource = (site) => {
  if (`${baseClient}-${baseStage}` !== contentSource) {
    return false;
  }
  return site === contentSourceSite;
};

export const formatDuration = (msDuration) => {
  const diff = moment.duration(msDuration);

  let result = '';
  const days = Math.floor(diff.asDays());
  if (days > 0) {
    result += `${days} day${getPluralS(days)} `;
    diff.add(-days, 'd');
  }
  const hours = Math.floor(diff.asHours());
  if (hours > 0) {
    result += `${hours} hour${getPluralS(hours)} `;
    diff.add(-hours, 'h');
  }
  const minutes = Math.floor(diff.asMinutes());
  if (minutes > 0) {
    result += `${minutes} minute${getPluralS(minutes)} `;
    diff.add(-minutes, 'm');
  }
  if (_.isEmpty(result)) {
    return 'Less than a minute';
  }
  return result.trim();
};

export const readJSONFromStorage = (storage, key, fallback) => {
  const value = storage.getItem(key);
  if (value) {
    return JSON.parse(value);
  }
  return fallback;
};

export const readStorageWithCookie = (key, isJson) => {
  const value = window.localStorage.getItem(key);
  if (value) {
    if (isJson) {
      try {
        return JSON.parse(value);
      } catch (e) {
        console.log('parse error', e);
      }
      return null;
    }
    return value;
  }
  if (isJson) {
    return Cookies.getJSON(key);
  }
  return Cookies.get(key);
};

export const clearLocalStorage = (key) => {
  window.localStorage.removeItem(key);
  Cookies.remove(key);
};

export const setLocalStorage = (key, value, isJson) => {
  window.localStorage.setItem(key, isJson ? JSON.stringify(value) : value);
};

export const TIMESTAMPFORMAT = 'D MMM YYYY h:mma';

export const featureInfo = [
  ...extensionFeatureInfo,
  {
    key: 'dashboard',
    order: 1,
    text: 'Dashboard',
    icon: 'dashboard',
    isFontAwesome: false,
    url: '/mastermenu',
    countProps: null,
    visibleExps: null,
  },
  {
    key: 'events',
    order: 2,
    text: 'Events',
    icon: 'event',
    isFontAwesome: false,
    url: '/events',
    addUrl: '/events/event',
    addPermission: 'events',
    singularName: 'event',
    countProps: ['eventSubmissions', 'length'],
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'events' },
        {
          type: 'or',
          exps: [
            { type: 'permission', value: 'events' },
            { type: 'permission', value: 'eventAttendance' },
            { type: 'permission', value: 'eventSubmit' },
          ],
        },
      ],
    },
  },
  {
    key: 'alerts',
    order: 4,
    text: 'Alerts',
    icon: 'exclamation-triangle',
    isFontAwesome: true,
    url: '/alerts',
    addUrl: '/alerts/addalert',
    addPermission: 'alerts',
    singularName: 'alert',
    countProps: null,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'alerts' },
        { type: 'permission', value: 'alerts' },
      ],
    },
  },
  {
    key: 'users',
    order: 5,
    text: 'Users',
    icon: 'people',
    isFontAwesome: false,
    url: '/usershub',
    addUrl: '/usershub/adduser',
    addPermission: 'userManagement',
    singularName: 'user',
    countProps: null,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'users' },
        { type: 'permission', value: 'userManagement' },
        { type: 'state', value: 'adminOpen' },
      ],
    },
  },
  {
    key: 'gallery',
    order: 6,
    text: 'Gallery',
    icon: 'picture-o',
    isFontAwesome: true,
    url: '/imageLibrary',
    addPermission: 'addToPublishingImageLibrary',
    singularName: 'gallery image',
    countProps: null,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'gallery' },
        { type: 'permission', value: 'addToPublishingImageLibrary' },
        { type: 'state', value: 'adminOpen' },
      ],
    },
  },
  {
    key: 'services',
    order: 7,
    text: 'Services',
    icon: 'stethoscope',
    isFontAwesome: true,
    url: '/services',
    addUrl: '/services/service',
    addPermission: 'services',
    singularName: 'service',
    countProps: null,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'services' },
        { type: 'permission', value: 'services' },
        { type: 'state', value: 'adminOpen' },
      ],
    },
  },
  {
    key: 'sponsors',
    order: 8,
    text: 'Sponsors',
    icon: 'star-o',
    isFontAwesome: true,
    url: '/sponsors',
    addUrl: '/sponsors/sponsor',
    addPermission: 'services',
    singularName: 'sponsor',
    countProps: null,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'services' },
        { type: 'permission', value: 'services' },
        { type: 'state', value: 'adminOpen' },
      ],
    },
  },
  {
    key: 'info',
    order: 9,
    text: 'Information',
    icon: 'info',
    isFontAwesome: false,
    url: '/info',
    addUrl: '/info/infopage',
    addPermission: 'infoPages',
    singularName: CONST_STRINGS.FAQ_ENTRY,
    countProps: null,
    visibleExps: {
      type: 'or',
      exps: [
        {
          type: 'and',
          exps: [
            { type: 'feature', value: 'importantContacts' },
            { type: 'permission', value: 'importantContacts' },
          ],
        },
        {
          type: 'and',
          exps: [
            { type: 'feature', value: 'maps' },
            { type: 'permission', value: 'maps' },
          ],
        },
        {
          type: 'and',
          exps: [
            { type: 'feature', value: 'infoPages' },
            { type: 'permission', value: 'infoPages' },
          ],
        },
        // {
        //   type: 'and',
        //   exps: [
        //     { type: 'feature', value: 'news' },
        //     { type: 'permission', value: 'welcomePages' },
        //   ],
        // },
        { type: 'permission', value: 'terms' },
      ],
    },
  },
  {
    key: 'surveys',
    order: 11,
    text: 'Surveys',
    icon: 'pie-chart',
    isFontAwesome: true,
    url: '/polls',
    addUrl: '/polls/poll',
    addPermission: 'polls',
    singularName: 'survey',
    countProps: null,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'surveys' },
        { type: 'permission', value: 'polls' },
        { type: 'state', value: 'conciergeOpen' },
      ],
    },
  },
  {
    key: 'facilities',
    order: 12,
    text: CONST_STRINGS.FACILITIES,
    icon: 'facility',
    isFontAwesome: false,
    url: '/facilities',
    addUrl: '/facilities/facility',
    addPermission: 'facilities',
    singularName: 'facility',
    countProps: null,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'facilities' },
        { type: 'permission', value: 'facilities' },
        { type: 'state', value: 'conciergeOpen' },
      ],
    },
  },
  {
    key: 'offers',
    order: 13,
    text: 'Offers',
    icon: 'shopping-bag',
    isFontAwesome: true,
    url: '/offers',
    addUrl: '/offers/offer',
    addPermission: 'offers',
    singularName: 'offer',
    countProps: null,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'offers' },
        { type: 'permission', value: 'offers' },
        { type: 'state', value: 'conciergeOpen' },
      ],
    },
    linkableContent: {
      hasContent: true,
      //TODO expose content
    },
  },
  {
    key: 'signin',
    order: 14,
    text: 'Sign Ins',
    icon: 'signin',
    isFontAwesome: false,
    url: '/signinHub',
    addUrl: null,
    addPermission: 'visitors',
    singularName: 'visitor',
    countProps: null,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'visitors' },
        { type: 'permission', value: 'visitors' },
        { type: 'state', value: 'conciergeOpen' },
      ],
    },
  },
  {
    key: 'food',
    order: 15,
    text: 'Restaurant Manager',
    icon: 'cutlery',
    isFontAwesome: true,
    url: '/food',
    addUrl: null,
    addPermission: 'food',
    singularName: 'dish',
    countProps: null,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'feature', value: 'food' },
        { type: 'permission', value: 'food' },
      ],
    },
  },
  {
    key: 'payment',
    order: 16,
    text: 'Payment',
    icon: 'usd',
    isFontAwesome: true,
    url: '/payment',
    addUrl: null,
    addPermission: null,
    singularName: 'payment',
    countProps: null,
    visibleExps: {
      type: 'and',
      exps: [
        { type: 'props', value: 'paymentEnabled' },
        {
          type: 'or',
          exps: [
            { type: 'permission', value: 'managePayments' },
            { type: 'permission', value: 'viewPayments' },
          ],
        },
      ],
    },
  },
  {
    key: 'automation',
    order: 17,
    text: 'Automation',
    icon: 'bolt',
    isFontAwesome: true,
    url: '/automationHub',
    addUrl: null,
    addPermission: null,
    singularName: 'automation',
    countProps: null,
    visibleExps: { type: 'master', value: true },
  },
  {
    key: 'master',
    order: 17,
    text: 'Master',
    icon: 'tasks',
    isFontAwesome: true,
    url: '/master',
    addUrl: null,
    addPermission: null,
    singularName: 'master',
    countProps: null,
    visibleExps: { type: 'master', value: true },
  },
];

export const getLinkedContentTypes = (enabledFeatures) => {
  const validTypes = _.filter(enabledFeatures, (f) => {
    const fInfo = getFeatureInfo(f);
    return fInfo && fInfo.linkableContent && fInfo.linkableContent.hasContent;
  });
  return validTypes.map((t) => {
    const fInfo = getFeatureInfo(t);
    return {
      Key: t,
      Title: fInfo.text,
    };
  });
};

export const getFeatureInfo = (type) => {
  switch (type) {
    case 'maps':
      return {
        key: 'maps',
        icon: 'map',
        isFontAwesome: true,
        url: '/info',
        addUrl: '/info/map',
        addPermission: 'maps',
        singularName: 'map',
      };
    case 'importantContacts':
      return {
        key: 'importantContacts',
        icon: 'address-card-o',
        isFontAwesome: true,
        url: '/info',
        addUrl: '/info/importantcontact',
        addPermission: 'importantContacts',
        singularName: 'contact',
      };
    case 'info':
    case 'infoPages':
      return featureInfo.find((n) => n.key === 'info');
    default:
      let info = featureInfo.find(
        (n) =>
          n.key === type ||
          // check for matching alias
          _.some(extensionAliases, (alias) => {
            return (
              alias.key === n.key &&
              _.some(alias.aliases, (aliasKey) => {
                return aliasKey === type;
              })
            );
          }),
      );
      return info || { key: type, icon: type };
  }
};
