import React, { Component } from 'react';
import { connect } from 'react-redux';
import { welcomePagesUpdate } from '../../actions';
import {
  Button,
  GenericInput,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  AudienceSelector,
  SuccessPopup,
  ImageInput,
  TextFormatPopup,
} from '../../components';
import { infoActions } from '../../webapi';
import { safeReadParams, get1400 } from '../../helper';
import { checkLoggedIn } from '../../session';
import { Text } from '../../components/text';

class AddWelcomePage extends Component {
  initialState = {
    infoId: safeReadParams(this.props, 'infoId'),
    titleInput: '',
    textInput: '',
    showWarnings: false,
    success: false,
    submitting: false,
    isAudienceValid: true,
  };

  state = { ...this.initialState };

  UNSAFE_componentWillReceiveProps(nextProps) {}

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
    if (this.state.infoId) {
      this.getData();
    }
  }

  updateAudienceValidation(valid) {
    this.setState({
      isAudienceValid: valid,
    });
  }

  getData() {
    infoActions.getWelcomePage(this.state.infoId).then((res) => {
      this.parseUpdate(res.data);
    });
  }

  parseUpdate(update) {
    this.setState({
      titleInput: update.Title,
      textInput: update.Text,
    });
    if (this.audienceSelector) {
      this.audienceSelector.getWrappedInstance().setType(update.AudienceType, update.AudienceTypeSelection);
    }
    this.checkSetImage(update);
  }

  checkSetImage(update) {
    if (this.refs.imageInput) {
      if (update.Images) {
        this.refs.imageInput.getWrappedInstance().setValue(update.Images);
      } else {
        this.refs.imageInput.getWrappedInstance().setValue(update.Image);
      }
    } else {
      setTimeout(() => {
        this.checkSetImage(update);
      }, 100);
    }
  }

  toggleTextFormat(isOpen) {
    this.setState({
      textFormatOpen: isOpen,
    });
  }

  isEmpty(text) {
    return text === '';
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  validateLoading() {
    if (this.state.submitting) {
      return false;
    }
    return true;
  }

  validateCompulsoryText() {
    if (this.isEmpty(this.state.titleInput)) {
      return false;
    }
    if (this.isEmpty(this.state.textInput)) {
      return false;
    }
    return true;
  }

  validateForm() {
    if (!this.validateCompulsoryText()) {
      return false;
    }
    if (!this.state.isAudienceValid) {
      return false;
    }
    if (!this.validateLoading()) {
      return false;
    }
    return true;
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ submitting: true });

    if (this.state.infoId) {
      infoActions
        .editWelcomePage(
          this.props.auth.site,
          this.state.infoId,
          this.state.titleInput,
          this.state.textInput,
          this.refs.imageInput
            .getWrappedInstance()
            .getValue()
            .map((url) => {
              return get1400(url);
            }),
          this.audienceSelector.getWrappedInstance().getAudienceType(),
          this.audienceSelector.getWrappedInstance().getAudienceTypeSelection(),
        )
        .then((res) => {
          this.setState({
            success: true,
            submitting: false,
          });
          this.props.welcomePagesUpdate(this.props.auth.site);
        })
        .catch((res) => {
          this.setState({ submitting: false });
          alert('Something went wrong with the request. Please try again.');
        });
    } else {
      infoActions
        .addWelcomePage(
          this.props.auth.site,
          this.state.titleInput,
          this.state.textInput,
          this.refs.imageInput
            .getWrappedInstance()
            .getValue()
            .map((url) => {
              return get1400(url);
            }),
          this.audienceSelector.getWrappedInstance().getAudienceType(),
          this.audienceSelector.getWrappedInstance().getAudienceTypeSelection(),
        )
        .then((res) => {
          this.setState({
            success: true,
            submitting: false,
          });
          this.props.welcomePagesUpdate(this.props.auth.site);
        })
        .catch((res) => {
          this.setState({ submitting: false });
          alert('Something went wrong with the request. Please try again.');
        });
    }
  }

  clearForm() {
    this.setState(this.initialState);
  }

  clearSuccess() {
    this.setState({
      success: false,
      submitting: false,
      showWarnings: false,
    });
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <SuccessPopup
        text={`Welcome page has been ${this.state.infoId != null ? 'edited' : 'added'}`}
        buttons={[
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: 'Go to home',
          },
        ]}
      />
    );
  }

  inputsDisabled() {
    if (this.state.submitting) {
      return true;
    }
    return false;
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button
          inline
          buttonType="tertiary"
          onClick={() => {
            window.history.back();
          }}
          isActive
          style={{ marginRight: 16 }}
        >
          {!this.inputsDisabled() ? 'Cancel' : 'Back'}
        </Button>
        {!this.inputsDisabled() && (
          <Button inline buttonType="primary" onClick={() => this.handleSubmit()} isActive={this.validateForm()}>
            Save
          </Button>
        )}
      </div>
    );
  }

  renderForm() {
    if (this.state.success) {
      return null;
    }
    return (
      <div>
        <div className="padding-60 paddingBottom-40 bottomDivideBorder">
          <Text type="formTitleLarge" className="marginBottom-24">
            {this.state.infoId == null ? 'New' : 'Edit'} Welcome Page
          </Text>
          {/* title */}
          <GenericInput
            id="titleInput"
            type="text"
            label="Title"
            placeholder="Insert title here"
            value={this.state.titleInput}
            onChange={(e) => this.handleChange(e)}
            isRequired
            isValid={() => {
              return !this.isEmpty(this.state.titleInput);
            }}
            showError={() => {
              return this.state.showWarnings && this.isEmpty(this.state.titleInput);
            }}
            alwaysShowLabel
          />
          {/* description */}
          <GenericInput
            id="textInput"
            label="Text"
            type="textarea"
            componentClass="textarea"
            placeholder="Insert text here"
            value={this.state.textInput}
            onChange={(e) => this.handleChange(e)}
            inputStyle={{
              height: 120,
            }}
            isRequired
            isValid={() => {
              return !this.isEmpty(this.state.textInput);
            }}
            showError={() => {
              return this.state.showWarnings && this.isEmpty(this.state.textInput);
            }}
            help={
              <a
                onClick={() => {
                  this.toggleTextFormat(true);
                }}
                className="pointer"
              >
                Text formatting
              </a>
            }
            alwaysShowLabel
          />
        </div>
        <div className="padding-60 paddingTop-40">
          {/* image */}
          <Text type="formTitleSmall" className="marginBottom-16">
            Images
          </Text>
          {/* image */}
          <ImageInput ref="imageInput" multiple limit={10} containerStyle={{ width: 960 }} />
        </div>
      </div>
    );
  }

  renderSideForm() {
    if (this.state.success) {
      return null;
    }
    return (
      <OverlayPageSection className="pageSectionWrapper--newPopupSide">
        <div className="padding-32 paddingVertical-40 bottomDivideBorder">
          <AudienceSelector
            updateValidation={this.updateAudienceValidation.bind(this)}
            ref={(a) => {
              this.audienceSelector = a;
            }}
            auth={this.props.auth}
            title="Select Who Sees This Post"
            hideSite
            custom
            maxWidth={300}
            disallowSingleUsers
            disallowUserType
          />
        </div>
      </OverlayPageSection>
    );
  }

  render() {
    return (
      <OverlayPage>
        <TextFormatPopup onClose={this.toggleTextFormat.bind(this, false)} isOpen={this.state.textFormatOpen} />
        <OverlayPageContents noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--newPopup">
            {this.renderForm()}
            {this.renderSuccess()}
          </OverlayPageSection>
          {this.renderSideForm()}
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { welcomePagesUpdate })(AddWelcomePage);
