import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router';
import FontAwesome from 'react-fontawesome';
import { ChromePicker } from 'react-color';
import Reorder from 'react-reorder';
import tinycolor from 'tinycolor2';
import Switch from 'react-switch';
import $ from 'jquery';
import { Lottie } from '@crello/react-lottie';
import { Button, Tabs, ImageInput, GenericInput, Popup, CheckBox, RadioButton, SVGIcon } from '../../../components';
import { checkLoggedIn, isTheBest, unauthedFunction, validateAccess } from '../../../session';
import { typeActions } from '../../../webapi';
import { refreshAuthUser, setAuthLocation, setNavData } from '../../../actions';
import { CONST_STRINGS } from '../../../config';
import {
  TEXT_LIGHT,
  pctBtwnColours,
  COLOUR_BRANDING_MAIN,
  COLOUR_DUSK_LIGHTER,
  COLOUR_BRANDING_APP,
  COLOUR_BRANDING_ACTION,
  COLOUR_DUSK,
} from '../../../js';
import { capitalize, getPluralOptions, getUrlParams, safeReadParams } from '../../../helper';
import templates from '../../../json/featurePicker/featurePickerTemplates.json';
import headerPatterns from '../../../json/featurePicker/headerPatterns.json';
import tabIcons from '../../../json/featurePicker/tabIcons.json';
import SiteSignUpComplete from './SiteSignUpComplete';
import { OnboardingFade, OnboardingStep } from '../../../components/onboarding';
import { Text } from '../../../components/text';
import {
  extensionWidgetInfos,
  extensionWidgetOptions,
  extensionWidgetKeys,
  extensionHiddenSections,
  extensionMoreSections,
  getExtensionViewWidget,
  getExtensionViewFull,
  getExtensionPreviewWidget,
  getExtensionPreviewFull,
  getExtensionPreviewGridIcon,
} from '../../../config/features';

class FeaturePicker extends Component {
  constructor(props) {
    super(props);

    const urlParams = getUrlParams();

    this.state = {
      ...typeActions.getDefaultSiteSettings(props.isNew, props.fromTemplate, props.signup),
      interfaceTitle: urlParams.title ? decodeURIComponent(urlParams.title) : null,
      interfaceId: safeReadParams(this.props, 'interfaceId') || (urlParams.interfaceId ? decodeURIComponent(urlParams.interfaceId) : null),
      interfaces: this.props.navData.interfaces || [],
      interfaceInput: '',
      userTypes: this.props.navData.userTypes || [],
      anyChanges: false,
      siteNameInput: urlParams.siteName ? decodeURIComponent(urlParams.siteName) : '',
      plan: urlParams.plan ? decodeURIComponent(urlParams.plan) : '',
      selectedQuickChoice: 'vibrant',
      patternOptions: _.cloneDeep(headerPatterns),
      caretVisible: false,
      lottieConfig: {
        path: 'https://pluss-prd-media.s3-ap-southeast-2.amazonaws.com/assets/config-lottie.json',
        loop: true,
        name: 'config-lottie',
      },
    };

    this.widgetKeys = [...extensionWidgetKeys, 'events', 'offers', 'surveys', 'groups', 'people', 'services', 'facilities', 'gallery'];

    this.widgetInfoAll = {
      ...extensionWidgetInfos,
      events: {
        description:
          'List down your upcoming events on the community through this feature here! Your members can start reserving their spot early.',
        emptyText: 'There are no Events coming up',
      },
      offers: {
        description: 'Share deals from the local area.',
        emptyText: 'There are no current Offers',
      },
      surveys: {
        description: 'Vote or poll groups of active users, linked users or staff on issues.',
        emptyText: 'There are no current Surveys',
      },
      groups: {
        description: 'App users can create groups and message each other.',
        emptyText: "You aren't in any Groups",
      },
      people: {
        description: 'Users of the app can message each other privately or call them directly from the app.',
        emptyText: 'Contacts will show here',
      },
      services: {
        description: 'Community Partners that can offer bookable actions or sponsored posts to your users.',
        emptyText: 'No Services available',
      },
      facilities: {
        description:
          'List the many rooms, areas and fixed spaces you have available for your residents, village and guests. For informational benefits or for bookings.',
        emptyText: "Facilities haven't been added",
      },
      gallery: {
        description: 'Create a Community Gallery where you can share photos to users of the app.',
        emptyText: 'Gallery images will show here',
      },
    };

    this.widgetOptionsAll = [
      ...extensionWidgetOptions,
      {
        key: 'events', // the associated widget's key
        widget: false, // whether to be active as part of the widget options
        main: true, // whether to be active as part of main item options
        hideOnTabNumbers: [0], // whether to hide the option for certain tab numbers
        optionKey: 'whatsOnDatePicker', // the state key to toggle
        inverseOption: true, // whether the option is inverted (true means hidden)
        title: 'Show Date Picker', // title in the option box
        description:
          'Allow users to click through to dates of the month. Ideal for Communities with frequent events over the upcoming month.', // description in the option box
      },
      {
        key: 'events',
        widget: false,
        main: true,
        hideOnTabNumbers: [0],
        optionKey: 'whatsOnEmptyDates',
        inverseOption: true,
        title: 'Show Empty Dates',
        description:
          'Dates without Events are visualised on the Event list. This may be a scrolling hazard if you do not have frequent events.',
      },
      {
        key: 'events',
        widget: true,
        main: true,
        hideOnTabNumbers: [],
        optionKey: 'eventRegistration',
        inverseOption: true,
        title: 'Allow Event Registration',
        description: 'Users can register for your Events within the app.',
      },
      {
        key: 'events',
        widget: true,
        main: true,
        hideOnTabNumbers: [],
        optionKey: 'eventComments',
        inverseOption: true,
        title: 'Allow Event Comments',
        description: 'Users can post comments on Events within the app.',
      },
      {
        key: 'events',
        widget: true,
        main: true,
        hideOnTabNumbers: [],
        optionKey: 'eventNotifications',
        inverseOption: true,
        title: 'Allow Event Notifications',
        description: 'When setting up an event, the creator may choose to send a push notification to users of the app.',
      },
      {
        key: 'events',
        widget: true,
        main: false,
        hideOnTabNumbers: [],
        optionKey: 'useEventImage',
        inverseOption: true,
        title: 'Simplified Events Style',
        description: 'This does not display the image of the event.',
      },
      {
        key: 'events',
        widget: false,
        main: true,
        hideOnTabNumbers: [],
        optionKey: 'eventFilter',
        inverseOption: false,
        title: 'Enable Event Filters',
        description: 'This will add an option to filter events based on location.',
      },
      {
        key: 'people',
        widget: false,
        main: true,
        hideOnTabNumbers: [],
        optionKey: 'phonePeople',
        inverseOption: true,
        title: 'Allow Calls to Other People',
        description:
          'Gives users the choice to have their phone number, if provided, able to be called by others in the community allowed to see their profile.',
      },
      {
        key: 'people',
        widget: true,
        main: true,
        hideOnTabNumbers: [],
        optionKey: 'peopleFilter',
        inverseOption: false,
        title: 'Enable Filtering People',
        description: 'Gives users the option to search for particular people. Useful for communities with many members.',
      },
      {
        key: 'surveys',
        widget: true,
        main: true,
        hideOnTabNumbers: [],
        optionKey: 'surveyNotifications',
        inverseOption: true,
        title: 'Allow Survey Notifications',
        description: 'When setting up a survey, the creator may choose to send a push notification to users of the app.',
      },
      {
        key: 'facilities',
        widget: true,
        main: true,
        hideOnTabNumbers: [],
        optionKey: 'facilityNotifications',
        inverseOption: true,
        title: 'Allow Facility Notifications',
        description: 'When setting up a facility, the creator may choose to send a push notification to users of the app.',
      },
      {
        key: 'services',
        widget: true,
        main: true,
        hideOnTabNumbers: [],
        optionKey: 'serviceNotifications',
        inverseOption: true,
        title: 'Allow Service Notifications',
        description: 'When setting up a service, the creator may choose to send a push notification to users of the app.',
      },
      {
        key: 'offers',
        widget: true,
        main: true,
        hideOnTabNumbers: [],
        optionKey: 'offerNotifications',
        inverseOption: true,
        title: 'Allow Offer Notifications',
        description: 'When setting up an offer, the creator may choose to send a push notification to users of the app.',
      },
    ];

    this.moreOptions = [
      ...extensionMoreSections,
      {
        displayName: 'Profile',
        optionKey: 'profile',
        inverseOption: true,
      },
      {
        displayName: CONST_STRINGS.FAQS,
        optionKey: 'infoPages',
        inverseOption: true,
      },
      {
        displayName: 'Surveys',
        optionKey: 'surveys',
        inverseOption: true,
      },
      {
        displayName: 'Maps',
        optionKey: 'maps',
        inverseOption: true,
      },
      {
        displayName: 'Contacts',
        optionKey: 'importantContacts',
        inverseOption: true,
      },
      {
        displayName: 'Give Feedback',
        optionKey: 'feedback',
        inverseOption: true,
      },
    ];

    this.iconOptions = _.filter(tabIcons, (t) => t.tag !== 'more').map((t) => {
      return t.key;
    });
    this.moreIconOptions = _.filter(tabIcons, (t) => t.tag === 'more').map((t) => {
      return t.key;
    });
  }

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    if (!this.props.fromTemplate && !this.props.isInterface) {
      if (!validateAccess(this.props.auth.site, 'featurePicker', this.props.auth)) {
        this.props.history.push('/mastermenu');
      }
    }
    if (!this.props.isNew || this.props.isInterface) this.getSiteSettings();
    this.hideTidio();

    this.props.setNavData({ hideSideMenu: true });
    setTimeout(() => {
      this.updateCaret();
      setTimeout(() => {
        this.setState({
          caretVisible: true,
        });
      }, 500);
    }, 1000);
    this.getLottieConfig();
  }

  getLottieConfig() {
    unauthedFunction({
      method: 'GET',
      url: this.state.lottieConfig.path,
    }).then((res) => {
      this.setState({
        lottieConfig: {
          ...this.state.lottieConfig,
          path: undefined,
          animationData: res.data,
        },
      });
    });
  }

  componentWillUnmount() {
    this.props.setNavData({ hideSideMenu: false });
  }

  componentDidUpdate(prevProps) {
    if (this.props.onboardingStep === 'intro_fp_options_1' && prevProps.onboardingStep !== 'intro_fp_options_1') {
      this.openOptions('main', 'news', 0, 0);
    } else if (this.props.onboardingStep !== 'intro_fp_options_1' && prevProps.onboardingStep === 'intro_fp_options_1') {
      this.openOptions();
    }
  }

  hideTidio() {
    if (this.hiddenTidio) {
      return;
    }
    if (window.tidioChatApi && window.tidioChatApi.display) {
      setTimeout(() => {
        window.tidioChatApi.display(false);
        this.hiddenTidio = true;
      }, 1000);
      return;
    }
    setTimeout(() => {
      this.hideTidio();
    }, 1000);
  }

  refreshInterfaces() {
    typeActions.getInterfaces(this.props.auth.site).then((res) => {
      this.setState({
        interfaces: res.data,
      });
    });
  }

  refreshUserTypes() {
    typeActions.getUserTypes(this.props.auth.site).then((res) => {
      this.setState({
        userTypes: res.data,
      });
    });
  }

  getSiteSettings() {
    this.setState({
      loading: true,
    });
    this.refreshInterfaces();
    this.refreshUserTypes();
    if (this.props.navData.site && this.props.navData.site.Id === this.props.auth.site) {
      this.setState({
        site: this.props.navData.site,
      });
      this.loadSiteState(this.props.navData.site);
      this.checkGetInterface();
    } else {
      typeActions.getSite(this.props.auth.site).then((res) => {
        this.setState({
          site: res.data,
        });
        this.loadSiteState(res.data);
        this.checkGetInterface();
      });
    }
  }

  checkGetInterface() {
    if (!_.isEmpty(this.state.interfaceId)) {
      if (this.props.navData.interface && this.props.navData.interface.Id === this.state.interfaceId) {
        this.setInterface(this.props.navData.interface);
      } else {
        typeActions.getInterface(this.state.interfaceId).then((res) => {
          this.setInterface(res.data);
        });
      }
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  setInterface(data) {
    this.setState({
      tabSettings: data.Settings,
      interfaceTitle: data.Title,
      loading: false,
    });
  }

  getAppInterfaces() {
    return _.filter(this.state.interfaces, (i) => {
      return i.Type.toLowerCase() === 'app';
    });
  }

  loadSiteState(data, fromTemplate) {
    let newState = {
      // tabSettings: [...typeActions.getOldSiteSettings().tabSettings],
      ...typeActions.getDefaultSiteSettings(this.props.isNew, this.props.fromTemplate, this.props.signup),
    };
    if (fromTemplate) {
      newState = { ...newState, ...this.state };
    }
    if (!this.props.isInterface) {
      // split into Hidden and NotHidden to control which default to hidden and which default to showing
      if (data && data.Hidden) {
        extensionHiddenSections.forEach((h) => {
          if (h && h.key) newState[h.key] = _.includes(data.Hidden, h.key);
        });
        extensionWidgetOptions.forEach((o) => {
          if (o && o.optionKey && o.type === 'show') newState[o.optionKey] = _.includes(data.Hidden, o.optionKey);
        });
        newState.whatsOn = _.includes(data.Hidden, 'whatsOn');
        newState.marketplace = _.includes(data.Hidden, 'marketplace');
        newState.people = _.includes(data.Hidden, 'people');
        newState.more = _.includes(data.Hidden, 'more');
        newState.profile = _.includes(data.Hidden, 'profile');
        newState.infoPages = _.includes(data.Hidden, 'infoPages');
        newState.maps = _.includes(data.Hidden, 'maps');
        newState.surveys = _.includes(data.Hidden, 'surveys');
        newState.importantContacts = _.includes(data.Hidden, 'importantContacts');
        newState.feedback = _.includes(data.Hidden, 'feedback');
        newState.whatsOnDatePicker = _.includes(data.Hidden, 'whatsOnDatePicker');
        newState.whatsOnEmptyDates = _.includes(data.Hidden, 'whatsOnEmptyDates');
        newState.eventRegistration = _.includes(data.Hidden, 'eventRegistration');
        newState.eventComments = _.includes(data.Hidden, 'eventComments');
        newState.eventNotifications = _.includes(data.Hidden, 'eventNotifications');
        newState.serviceNotifications = _.includes(data.Hidden, 'serviceNotifications');
        newState.facilityNotifications = _.includes(data.Hidden, 'facilityNotifications');
        newState.surveyNotifications = _.includes(data.Hidden, 'surveyNotifications');
        newState.offerNotifications = _.includes(data.Hidden, 'offerNotifications');
        newState.kioskFooter = _.includes(data.Hidden, 'kioskFooter');
        newState.tabTitles = _.includes(data.Hidden, 'tabTitles');
      }
      if (data && data.NotHidden) {
        newState.phonePeople = !_.includes(data.NotHidden, 'phonePeople');
        newState.visitors = !_.includes(data.NotHidden, 'visitors');
      }
    }
    if (data && data.Settings) {
      if (!fromTemplate) {
        newState.headerType = data.Settings.HeaderType || (!!data.Settings.UseGradientHeader ? 'gradient' : 'white');
        newState.footerType = data.Settings.FooterType || 'white';
        newState.footerColour = data.Settings.FooterColour;
        newState.patternColour = data.Settings.PatternColour;
        newState.usePatternColour = newState.headerType === 'pattern' && !!data.Settings.PatternColour;
        newState.selectedPattern = data.Settings.HeaderPattern;
        newState.useExpandedFeatured = !!data.Settings.UseExpandedFeatured;
        newState.useGlobalEvents = !!data.Settings.UseGlobalEvents;
        newState.kioskVisitors = !!data.Settings.KioskVisitors;
      }
      extensionWidgetOptions.forEach((o) => {
        if (o && o.optionKey && o.type === 'toggle') newState[o.optionKey] = !!data.Settings[o.optionKey];
      });
      newState.useEventImage = !!data.Settings.UseEventImage;
      newState.eventFilter = !!data.Settings.EventFilter;
      newState.peopleFilter = !!data.Settings.PeopleFilter;

      if (!this.props.isInterface) {
        if (data.Settings.HomeWidgets) {
          const widgets = data.Settings.HomeWidgets;
          newState.mainWidget = _.last(widgets);
          newState.topWidgets = widgets.splice(0, widgets.length - 1);
        }

        if (data.Settings.MainWidget1) {
          newState.mainWidget1 = data.Settings.MainWidget1;
        }
        if (data.Settings.MainWidget3) {
          newState.mainWidget3 = data.Settings.MainWidget3;
        }
        if (data.Settings.Widgets2) {
          newState.widgets2 = data.Settings.Widgets2;
        }

        if (data.Settings.TabTitles) {
          newState.tabTitle = { ...this.state.tabTitle, ...data.Settings.TabTitles };
        }
        if (data.Settings.TabSettings) {
          newState.tabSettings = [...data.Settings.TabSettings];
          newState.tabSettings.forEach((t) => {
            if (!t.widgetOptions) {
              t.widgetOptions = [];
            }
          });
        }
      }
    }
    if (!fromTemplate) {
      if (data && data.Branding && data.Branding) {
        if (data.Branding.MainBrandingColour) {
          newState.colour = data.Branding.MainBrandingColour;
        }
        if (data.Branding.Logo) {
          newState.logo = data.Branding.Logo;
        }
        newState.originalBranding = data.Branding;
      }
      if (data && !_.isEmpty(data.siteName)) {
        newState.siteNameInput = data.siteName;
      }
      this.setColourCircle(newState.colour || COLOUR_BRANDING_APP);
    }
    newState.selectedTab = newState.tabSettings[0].key;
    this.setState(_.cloneDeep(newState));
  }

  setColourCircle(colour) {
    const tc = tinycolor(colour);
    this.setState({
      colourCircleTop: 100 - (100 * tc.getBrightness()) / 255,
      colourCircleLeft: (100 * tc.toHsv().h) / 360,
    });
  }

  onLogoUpdated(url) {
    this.setState({
      logo: url,
      optionsChanged: true,
    });
  }

  onPatternUpdated(url) {
    if (!_.isEmpty(url)) {
      this.setState({
        patternOptions: _.uniqBy([...this.state.patternOptions, { url, title: _.last(url.split('/')) }], (v) => v.url),
        selectedPattern: url,
        optionsChanged: true,
      });
    }
  }

  onTogglePage(page, show) {
    page.isEnabled = show;
    this.setState({
      tabSettings: this.state.tabSettings,
      anyChanges: true,
    });
  }

  toggleLogoPopup(isOpen) {
    this.setState({
      logoPopupOpen: isOpen,
    });
  }

  toggleFooterPopup(isOpen) {
    this.setState({
      footerPopupOpen: isOpen,
    });
  }

  canCancel() {
    if (this.props.signup) {
      return false;
    }
    return true;
  }

  canAddNewPage() {
    return (
      _.filter(this.state.tabSettings, (t) => {
        return t.type !== 'menu';
      }).length < 4
    );
  }

  isPageGrid(page) {
    return page.template === 'grid';
  }

  getBackgroundType(page) {
    return page.template === 'grid' && page.backgroundType === 'block' ? 'block' : 'white';
  }

  getQuickChoiceTabs(type) {
    if (type === 'header') {
      return [
        {
          value: 'vibrant',
          text: 'Vibrant',
        },
        {
          value: 'soft',
          text: 'Soft',
        },
      ];
    }
    return [
      {
        value: 'vibrant',
        text: 'Vibrant',
      },
      {
        value: 'soft',
        text: 'Soft',
      },
      {
        value: 'colourPicker',
        text: 'Picker',
      },
    ];
  }

  selectQuickChoice(tab) {
    this.setState({
      selectedQuickChoice: tab,
    });
  }

  getTabs() {
    const result = [];
    return [
      ...result,
      ...this.state.tabSettings.map((t) => {
        return {
          value: t.key,
          text: t.tabTitle,
          strikethrough: !t.isEnabled,
          isEditable: t.type === 'blank',
          isEditing: this.state.isEditingTitle[t.key],
          maxLength: 15,
          isInvalid: !this.validatePage(t) && (this.state.selectedTab !== t.key || this.state.showWarnings),
        };
      }),
    ];
  }

  getCaretStyle() {
    return {
      transform: `translateX(${this.state.caretOffset}px)`,
      opacity: this.state.caretVisible ? 1 : 0,
    };
  }

  getHeaderStyle() {
    if (!this.state.usePatternColour) {
      return { backgroundImage: `url(${this.state.selectedPattern})` };
    }
    return {
      '-webkit-mask-image': `url(${this.state.selectedPattern})`,
      maskImage: `url(${this.state.selectedPattern})`,
      backgroundColor: this.state.patternColour || '#fff',
      // '-webkit-mask-size': 'cover',
      // maskSize: 'cover',
      // maskPosition: 'center',
    };
  }

  getTabKey(tabNumber) {
    return ['home', 'whatsOn', 'marketplace', 'people', 'more'][tabNumber];
  }

  getTabIconFromWidget(widget) {
    switch (widget) {
      case 'people':
      case 'groups':
        return 'friends';
      case 'surveys':
        return 'survey';
      case 'facilities':
        return 'foryou';
      default:
        return widget; // when icon name matches
    }
  }

  getTabKeyFromPage(page) {
    if (page.icon) {
      return page.icon;
    }
    if (page.type === 'home') {
      return 'home';
    }
    if (page.type === 'menu') {
      return 'more';
    }
    if (_.isEmpty(page.widgets)) {
      return 'empty';
    }
    return this.getTabIconFromWidget(page.widgets[0]);
  }

  openTemplatePopup = () => {
    this.setState({
      templatePopupOpen: true,
    });
  };

  closeTemplatePopup = () => {
    this.setState({
      templatePopupOpen: false,
    });
  };

  selectTemplate = (key) => {
    if (key === this.state.selectedTemplate) {
      return;
    }

    if (key === 'blank') {
      this.loadSiteState(this.state.preTemplateData, true);
      this.setState({
        selectedTemplate: null,
      });
      setTimeout(() => {
        this.selectTab('tab0');
      }, 200);
    } else {
      this.setState({
        preTemplateData: this.compileData(),
        updatingTemplate: true,
        updatingTemplateText: 'Changing layout...',
      });
      setTimeout(() => {
        this.setState({
          updatingTemplateText: 'Renaming features...',
        });
        setTimeout(() => {
          this.setState({
            updatingTemplateText: 'Selecting options...',
          });
          setTimeout(() => {
            this.setState({
              updatingTemplateText: 'Done!',
              selectedTemplate: key,
            });
            this.loadSiteState(templates[key].value, true);
            this.selectTab('tab0');
            setTimeout(() => {
              this.setState({
                updatingTemplateText: null,
                updatingTemplate: false,
                templatePopupOpen: false,
              });
            }, 1000);
          }, Math.random() * 1000 + 500);
        }, Math.random() * 1000 + 500);
      }, Math.random() * 1000 + 1000);
    }
  };

  openAddInterface = () => {
    if (this.state.anyChanges && !window.confirm('You have unsaved changes. Are you sure you want to continue?')) {
      return;
    }
    this.setState({ showAddInterface: true, interfaceTitleInput: '', interfaceUserTypes: _.cloneDeep(this.state.userTypes) });
  };

  closeAddInterface = () => {
    this.setState({ showAddInterface: false });
  };

  saveNewInterface = () => {
    if (_.isEmpty(this.state.interfaceTitleInput)) {
      return;
    }
    this.setState({
      savingInterface: true,
    });
    typeActions
      .saveInterface(this.props.auth.site, 'app', this.state.interfaceTitleInput, typeActions.getDefaultTabSettings())
      .then((res) => {
        const promises = [];
        this.state.interfaceUserTypes.forEach((t) => {
          if (t.Interface === 'attach') {
            // change interface for user type
            promises.push(typeActions.attachInterface(this.props.auth.site, t.typeName, res.data.result.Id));
          }
        });

        Promise.all(promises)
          .then((multiRes) => {
            this.selectInterface(res.data.result);
          })
          .catch((multiErr) => {
            this.selectInterface(res.data.result);
          });
      })
      .catch((err) => {
        window.alert('Something went wrong');
        this.setState({
          savingInterface: false,
        });
      });
  };

  selectInterface = (iFace) => {
    if (this.state.anyChanges && !window.confirm('You have unsaved changes. Are you sure you want to continue?')) {
      return;
    }
    if (iFace === null) {
      this.props.setNavData({ site: this.state.site, interfaces: this.state.interfaces, userTypes: this.state.userTypes });
      this.props.history.push(`/featurepicker`);
      return;
    }
    this.props.setNavData({ interface: iFace, site: this.state.site, interfaces: this.state.interfaces, userTypes: this.state.userTypes });
    setTimeout(() => {
      this.props.history.push(`/interface/${encodeURIComponent(iFace.Id)}`);
    }, 50);
  };

  editInterface = (iFace) => {
    this.setState({
      editingInterface: iFace,
      interfaceTitleInput: iFace.Title,
      interfaceUserTypes: _.cloneDeep(this.state.userTypes),
    });
  };

  toggleNewUserTypeInterface = (t) => {
    if (t.Interface === 'attach') {
      t.Interface = null;
    } else {
      t.Interface = 'attach';
    }
    this.setState({
      interfaceUserTypes: [...this.state.interfaceUserTypes],
    });
  };

  toggleUserTypeInterface = (t) => {
    if (t.Interface === this.state.editingInterface.Id) {
      t.Interface = null;
    } else {
      t.Interface = this.state.editingInterface.Id;
    }
    this.setState({
      interfaceUserTypes: [...this.state.interfaceUserTypes],
    });
  };

  goToDeleteInterface = () => {
    this.setState({
      showConfirmInterfaceDelete: true,
      interfaceDeleteSwitchTo: null,
    });
  };

  cancelDeleteInterface = () => {
    this.setState({
      showConfirmInterfaceDelete: false,
    });
  };

  confirmDeleteInterface = () => {
    this.setState({
      savingInterface: true,
    });

    const promises = [];

    const usingInterface = _.filter(this.state.userTypes, (t) => {
      return t.Interface === this.state.editingInterface.Id;
    });
    usingInterface.forEach((t) => {
      promises.push(typeActions.attachInterface(this.props.auth.site, t.typeName, this.state.interfaceDeleteSwitchTo));
    });

    promises.push(typeActions.deleteInterface(this.state.editingInterface.Id));
    Promise.all(promises).then((res) => {
      this.refreshInterfaces();
      this.refreshUserTypes();
      setTimeout(() => {
        this.selectInterface(
          this.state.interfaceDeleteSwitchTo != null
            ? _.find(this.state.interfaces, (i) => {
                return i.Id === this.state.interfaceDeleteSwitchTo;
              })
            : null,
        );
      }, 500);
    });
  };

  interfaceDeleteSwitchTo = (id) => {
    this.setState({
      interfaceDeleteSwitchTo: id,
    });
  };

  saveEditInterface = () => {
    if (_.isEmpty(this.state.interfaceTitleInput)) {
      return;
    }
    this.setState({
      savingInterface: true,
    });
    const promises = [];
    if (this.state.interfaceTitleInput !== this.state.editingInterface.Title) {
      // update interface title
      promises.push(
        typeActions.saveInterface(
          this.props.auth.site,
          undefined,
          this.state.interfaceTitleInput,
          undefined,
          this.state.editingInterface.Id,
        ),
      );
    }
    this.state.interfaceUserTypes.forEach((newType) => {
      const oldType = _.find(this.state.userTypes, (t) => {
        return t.typeName === newType.typeName;
      });
      if (oldType.Interface !== newType.Interface) {
        // change interface for user type
        promises.push(typeActions.attachInterface(this.props.auth.site, newType.typeName, newType.Interface));
      }
    });

    Promise.all(promises)
      .then((res) => {
        this.refreshInterfaces();
        this.refreshUserTypes();
        this.setState({
          savingInterface: false,
          editingInterface: null,
        });
      })
      .catch((err) => {
        this.refreshInterfaces();
        this.refreshUserTypes();
        this.setState({
          savingInterface: false,
          editingInterface: null,
        });
      });
  };

  goToTVPicker = () => {
    if (this.state.anyChanges && !window.confirm('You have unsaved changes. Are you sure you want to continue?')) {
      return;
    }
    const iFace = _.find(this.state.interfaces, (i) => {
      return i.Type === 'TV';
    });
    this.props.setNavData({ interface: iFace, site: this.state.site, interfaces: this.state.interfaces, userTypes: this.state.userTypes });

    setTimeout(() => {
      if (!iFace) {
        this.props.history.push(`/tvpicker`);
      } else {
        this.props.history.push(`/tvpicker/${encodeURIComponent(iFace.Id)}`);
      }
    }, 50);
  };

  toggleNewPage = () => {
    if (this.props.fromTemplate) {
      this.addNewPage('blank');
    } else {
      this.setState({
        showNewPage: !this.state.showNewPage,
      });
    }
  };

  isMoreEnabled = () => {
    return _.some(this.state.tabSettings, (t) => {
      return t.type === 'menu';
    });
  };

  onToggleMore = (moreEnabled) => {
    if (moreEnabled) {
      this.addNewPage('menu');
    } else {
      const newTabSettings = _.filter(this.state.tabSettings, (t) => {
        return t.type !== 'menu';
      });
      this.setState({
        tabSettings: newTabSettings,
        selectedTab: newTabSettings[0].key,
        anyChanges: true,
      });
      setTimeout(() => {
        this.updateCaret();
      }, 200);
    }
  };

  addNewPage = (type) => {
    let tabKey = null;
    if (type === 'menu') {
      tabKey = 'tab4';
      this.state.tabSettings.push({
        key: tabKey,
        tabTitle: 'More',
        type: 'menu',
        isEnabled: true,
        widgets: [],
        widgetOptions: [],
      });
    } else if (type === 'blank') {
      const pageNum =
        this.state.tabSettings.length -
        (_.some(this.state.tabSettings, (t) => {
          return t.type === 'menu';
        })
          ? 1
          : 0);
      tabKey = `tab${pageNum}`;
      const newTab = {
        key: tabKey,
        tabTitle: '',
        type: 'blank',
        isEnabled: true,
        widgets: [],
        widgetOptions: [],
      };
      if (
        _.some(this.state.tabSettings, (t) => {
          return t.type === 'menu';
        })
      ) {
        const pageNum = this.state.tabSettings.length - 1;
        this.state.tabSettings.splice(pageNum, 0, newTab);
      } else {
        this.state.tabSettings.push(newTab);
      }
      setTimeout(() => {
        document.getElementById(`tab_${tabKey}`).focus();
      }, 500);
    }
    this.setState({
      tabSettings: [...this.state.tabSettings],
      showNewPage: false,
      selectedTab: tabKey,
      anyChanges: true,
    });
    setTimeout(() => {
      console.log(this.state.tabSettings);
      this.updateCaret();
    }, 200);
  };

  toggleOption(o) {
    this.setState({
      [o.optionKey]: !this.state[o.optionKey],
      anyChanges: true,
    });
  }

  openOptions = (type, widget, tabNumber, widgetIndex, event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (!widget && !type) {
      this.setState({ optionsOpen: false });
    } else {
      this.setState({
        optionsOpen: {
          type,
          widget,
          tabNumber,
          widgetIndex,
        },
      });
    }
  };

  openPatternOptions = () => {
    this.setState({
      patternOptionsOpen: true,
    });
  };

  closePatternOptions = () => {
    this.setState({
      patternOptionsOpen: false,
    });
  };

  selectPatternOption = (url) => {
    this.setState({
      selectedPattern: url,
    });
  };

  onReorderWidgets(page, itemThatHasBeenMoved, itemsPreviousIndex, itemsNewIndex, reorderedArray) {
    const widgets = page.widgets;
    const itemsPreviousFixedIndex = widgets.length - itemsPreviousIndex - 1; // fix for reversed render ordering
    const itemsNewFixedIndex = widgets.length - itemsNewIndex - 1; // fix for reversed render ordering
    const widget = page.widgets[itemsPreviousFixedIndex];
    widgets.splice(itemsPreviousFixedIndex, 1);
    widgets.splice(itemsNewFixedIndex, 0, widget);
    if (page.widgetOptions) {
      const widgetOptions = page.widgetOptions;
      const option = widgetOptions[itemsPreviousFixedIndex];
      widgetOptions.splice(itemsPreviousFixedIndex, 1);
      widgetOptions.splice(itemsNewFixedIndex, 0, option);
    }
    this.setState({
      tabSettings: [...this.state.tabSettings],
      anyChanges: true,
    });
  }

  getWidgetOptions(type, widget, tabNumber) {
    return _.filter(this.widgetOptionsAll, (o) => {
      // filter to only show options relating to the currently selected widget
      if (widget !== o.key) {
        return false;
      }
      if (o.hideOnTabNumbers && _.includes(o.hideOnTabNumbers, tabNumber)) {
        return false;
      }
      return o[type];
    });
  }

  getWidgetOptionCount(type, widget, tabNumber) {
    return this.getWidgetOptions(type, widget, tabNumber).length;
  }

  getMainTileInfo(widget) {
    if (this.widgetInfoAll[widget] && this.widgetInfoAll[widget].description) {
      return this.widgetInfoAll[widget].description;
    }
    return '';
  }

  getMainTileClasses(tile, mainWidget, optionCount, selectedLength) {
    let classes = 'featurePicker_mainSection_tile featurePicker_mainSection_tile-mainItem';
    if (optionCount > 0) {
      classes += ' featurePicker_mainSection_tile-hasOptions';
    }
    if (mainWidget) {
      if (mainWidget === tile) {
        classes += ' featurePicker_mainSection_tile-selected';
      } else {
        classes += ' featurePicker_mainSection_tile-faded';
      }
    }
    if (selectedLength > 1) {
      classes += ' featurePicker_mainSection_tile-canMoveUp';
    }
    if (selectedLength === 3) {
      classes += ` featurePicker_mainSection_tile-condensed featurePicker_mainSection_tile-${tile}Widget`;
    } else {
      classes += ` featurePicker_mainSection_tile-${tile}`;
    }
    return classes;
  }

  getWidgetTileClasses(tile, optionCount, source, limit, selectedLength, index, isEditing, isGrid) {
    let classes = `featurePicker_mainSection_tile featurePicker_mainSection_tile-compressed featurePicker_mainSection_tile-${tile}Widget`;
    if (isEditing) {
      classes += ' featurePicker_mainSection_tile-isEditing';
    }
    if (optionCount > 0) {
      classes += ' featurePicker_mainSection_tile-hasOptions';
    }
    if (!_.isEmpty(source)) {
      if (_.includes(source, tile)) {
        classes += ' featurePicker_mainSection_tile-selected';
        if (selectedLength > 1 && index < selectedLength - 1) {
          if (isGrid) {
            classes += ' featurePicker_mainSection_tile-canMoveDown';
          } else {
            classes += ' featurePicker_mainSection_tile-canMoveUp';
          }
        }
        if (selectedLength > 1 && index > 0) {
          if (isGrid) {
            classes += ' featurePicker_mainSection_tile-canMoveUp';
          } else {
            classes += ' featurePicker_mainSection_tile-canMoveDown';
          }
        }
      } else if (source.length >= limit) {
        classes += ' featurePicker_mainSection_tile-faded';
      }
    }
    return classes;
  }

  getHeaderTileClasses(type) {
    let classes = `featurePicker_mainSection_tile featurePicker_mainSection_tile-header featurePicker_mainSection_tile-header-${type}`;
    if (type === this.state.headerType) {
      classes += ' featurePicker_mainSection_tile-selected';
    }
    return classes;
  }

  getFooterTileClasses(type) {
    let classes = `featurePicker_mainSection_tile featurePicker_mainSection_tile-footer featurePicker_mainSection_tile-footer-${type}`;
    if (type === this.state.footerType) {
      classes += ' featurePicker_mainSection_tile-selected';
    }
    return classes;
  }

  getWidgetTitleFromPage(page, index, widget) {
    if (page && page.widgetOptions && page.widgetOptions[index] && !_.isEmpty(page.widgetOptions[index].Title)) {
      return page.widgetOptions[index].Title;
    }
    return this.getWidgetTitle(widget);
  }

  getWidgetTitle(w) {
    return capitalize(w);
  }

  handleColourChange = (color) => {
    let colour = color;
    if (typeof color === 'object') {
      colour = color.hex;
    }
    this.setState({ colour, optionsChanged: true });
    this.setColourCircle(colour);
  };

  handlePatternColourChange = (color) => {
    let patternColour = color;
    if (typeof color === 'object') {
      patternColour = color.hex;
    }
    this.setState({ patternColour, optionsChanged: true });
  };

  handleFooterColourChange = (color) => {
    let footerColour = color;
    if (typeof color === 'object') {
      footerColour = color.hex;
    }
    this.setState({ footerColour, optionsChanged: true });
  };

  handleTabTitleChange = (key, value) => {
    _.find(this.state.tabSettings, (t) => {
      return t.key === key;
    }).tabTitle = value.replace(/\\n/g, ' ').substr(0, 15);
    this.setState({
      tabSettings: this.state.tabSettings,
      anyChanges: true,
    });
  };

  handleWidgetTitleChange = (tabNumber, widgetIndex, value) => {
    const page = this.state.tabSettings[tabNumber];
    if (!page.widgetOptions) {
      page.widgetOptions = [];
    }
    if (!page.widgetOptions[widgetIndex]) {
      page.widgetOptions[widgetIndex] = {};
    }
    page.widgetOptions[widgetIndex].Title = value;
    this.setState({
      tabSettings: this.state.tabSettings,
      anyChanges: true,
    });
  };

  startEditingWidgetTitle = (key) => {
    this.setState({
      isEditingWidgetTitle: key,
    });
  };

  endEditingWidgetTitle = () => {
    this.setState({
      isEditingWidgetTitle: null,
    });
  };

  openIconSelector = () => {
    this.setState({
      iconSelectorOpen: !this.state.iconSelectorOpen,
    });
  };

  selectIcon(page, icon) {
    page.icon = icon;
    this.setState({
      tabSettings: [...this.state.tabSettings],
      iconSelectorOpen: false,
      anyChanges: true,
    });
  }

  updateCaret(tab) {
    const tabToUse = tab || this.state.selectedTab;
    if (tabToUse === 'app') {
      this.setState({
        caretVisible: false,
      });
    } else {
      const tabContainer = $('#featurePicker_tabs');
      if (tabContainer.length === 0) {
        setTimeout(() => {
          this.updateCaret(tab);
        }, 1000);
        return;
      }
      const tabContainerOffset = tabContainer.offset().left;
      const tabOffset = $(`#${tabToUse}`).offset().left;
      const tabWidth = $(`#${tabToUse}`).width();
      this.setState({
        caretOffset: tabOffset + tabWidth / 2 - tabContainerOffset,
        caretVisible: true,
      });
    }
  }

  goToAppOptions = () => {
    this.setState({
      previousAppOptions: this.compileData(true),
      optionsChanged: false,
    });
    this.selectTab('app');
  };

  cancelAppOptions = () => {
    if (this.state.optionsChanged && !window.confirm('You have unsaved changes. Are you sure you want to continue?')) {
      return;
    }

    //load from previousAppOptions
    const data = this.state.previousAppOptions;

    const newState = {
      headerType: data.Settings.HeaderType || (!!data.Settings.UseGradientHeader ? 'gradient' : 'white'),
      footerType: data.Settings.FooterType || 'white',
      footerColour: data.Settings.FooterColour || null,
      selectedPattern: data.Settings.HeaderPattern,
      patternColour: data.Settings.PatternColour,
      optionsChanged: false,
    };
    if (data && data.Branding && data.Branding) {
      if (data.Branding.MainBrandingColour) {
        newState.colour = data.Branding.MainBrandingColour;
      }
      if (data.Branding.Logo) {
        newState.logo = data.Branding.Logo;
      }
      newState.originalBranding = data.Branding;
    }
    if (data && !_.isEmpty(data.siteName)) {
      newState.siteNameInput = data.siteName;
    }
    this.setState(newState);
    this.setColourCircle(newState.colour || COLOUR_BRANDING_APP);
    this.selectTab('tab0');
  };

  selectTab(tab) {
    this.setState({
      selectedTab: tab,
      iconSelectorOpen: false,
    });
    this.updateCaret(tab);
  }

  selectMainTile(widget, tabNumber, select, event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    const page = this.state.tabSettings[tabNumber];
    if (select) {
      if (_.isEmpty(page.widgets)) {
        page.widgets[0] = widget;
        page.widgetOptions[0] = {};
      }
    } else {
      page.widgets = [];
      page.widgetOptions = [];
    }
    this.setState({
      tabSettings: [...this.state.tabSettings],
      anyChanges: true,
    });
  }

  moveWidgetDown(tabNumber, widget, event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    const widgets = this.state.tabSettings[tabNumber].widgets;
    const index = widgets.indexOf(widget);
    if (index > 0) {
      widgets.splice(index, 1);
      widgets.splice(index - 1, 0, widget);
      if (this.state.tabSettings[tabNumber].widgetOptions) {
        const widgetOptions = this.state.tabSettings[tabNumber].widgetOptions;
        const option = widgetOptions[index];
        widgetOptions.splice(index, 1);
        widgetOptions.splice(index - 1, 0, option);
      }
    }
    this.setState({
      tabSettings: [...this.state.tabSettings],
      anyChanges: true,
    });
  }

  moveWidgetUp(tabNumber, widget, event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    const widgets = this.state.tabSettings[tabNumber].widgets;
    const index = widgets.indexOf(widget);
    if (index !== -1 && widgets.length > index + 1) {
      widgets.splice(index, 1);
      widgets.splice(index + 1, 0, widget);
      if (this.state.tabSettings[tabNumber].widgetOptions) {
        const widgetOptions = this.state.tabSettings[tabNumber].widgetOptions;
        const option = widgetOptions[index];
        widgetOptions.splice(index, 1);
        widgetOptions.splice(index + 1, 0, option);
      }
    }
    this.setState({
      tabSettings: [...this.state.tabSettings],
      anyChanges: true,
    });
  }

  selectWidgetForPage(widget, page, select, event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    const index = page.widgets.indexOf(widget);
    if (index > -1) {
      if (!select) {
        if (page.widgetOptions) {
          page.widgetOptions.splice(index, 1);
        }
        page.widgets.splice(index, 1);
      }
    } else if (select) {
      page.widgetOptions[page.widgets.length] = {};
      page.widgets = [...page.widgets, widget];
    }
    this.setState({
      tabSettings: this.state.tabSettings,
      anyChanges: true,
    });
  }

  getWidgetEmptyText(tabNumber, widgetIndex) {
    const page = this.state.tabSettings[tabNumber];
    if (!page || !page.widgetOptions || !page.widgetOptions[widgetIndex] || !page.widgetOptions[widgetIndex].EmptyText) {
      return '';
    }
    return page.widgetOptions[widgetIndex].EmptyText;
  }

  handleEmptyTextChange(event, tabNumber, widgetIndex) {
    const page = this.state.tabSettings[tabNumber];
    page.widgetOptions[widgetIndex].EmptyText = event.target.value;
    this.setState({
      tabSettings: this.state.tabSettings,
      anyChanges: true,
    });
  }

  getWidgetOptionText(tabNumber, widgetIndex, textProp) {
    const page = this.state.tabSettings[tabNumber];
    if (!page || !page.widgetOptions || !page.widgetOptions[widgetIndex] || !page.widgetOptions[widgetIndex][textProp]) return '';
    return page.widgetOptions[widgetIndex][textProp];
  }

  handleOptionTextChange(event, tabNumber, widgetIndex, textProp) {
    const page = this.state.tabSettings[tabNumber];
    page.widgetOptions[widgetIndex][textProp] = event.target.value;
    this.setState({
      tabSettings: this.state.tabSettings,
      anyChanges: true,
    });
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  changeHeader(value) {
    this.setState({
      headerType: value,
      optionsChanged: true,
    });
  }

  changeFooter(value) {
    const newState = {
      footerType: value,
      optionsChanged: true,
    };

    if (!this.state.footerColour) {
      newState.footerColour = this.state.colour;
    }

    this.setState(newState);
  }

  validateBranding() {
    if (this.props.isInterface) {
      return true;
    }
    if (_.isEmpty(this.state.siteNameInput)) {
      return false;
    }
    return true;
  }

  validateHomePage(page) {
    return !_.isEmpty(page.widgets) && page.isEnabled;
  }

  validateBlankPage(page) {
    if (!page.isEnabled) {
      return true;
    }
    if (_.isEmpty(page.widgets)) {
      return false;
    }
    if (_.isEmpty(page.tabTitle)) {
      return false;
    }
    return true;
  }

  validateMenuPage(page) {
    if (!page.isEnabled) {
      return true;
    }
    if (_.isEmpty(page.tabTitle)) {
      return false;
    }
    return true;
  }

  validatePage(page) {
    switch (page.type) {
      case 'home':
        return this.validateHomePage(page);
      case 'blank':
        return this.validateBlankPage(page);
      case 'menu':
        return this.validateMenuPage(page);
      default:
        break;
    }
    return false;
  }

  validateTabSettings() {
    if (_.isEmpty(this.state.tabSettings)) {
      return false;
    }
    let anyInvalid = false;
    this.state.tabSettings.forEach((p) => {
      if (!this.validatePage(p)) {
        anyInvalid = true;
      }
    });
    return !anyInvalid;
  }

  validateForm() {
    if (this.state.submitting) {
      return false;
    }
    if (!this.validateBranding()) {
      return false;
    }
    if (!this.validateTabSettings()) {
      return false;
    }
    return true;
  }

  compileData(onlyBranding) {
    const hidden = [];
    const notHidden = [];
    const settings = {};
    const branding = this.state.originalBranding || {};
    extensionHiddenSections.forEach((h) => {
      if (h && h.key && this.state[h.key]) hidden.push(h.key);
    });
    extensionWidgetOptions.forEach((o) => {
      if (o && o.optionKey && o.type === 'show' && this.state[o.optionKey]) hidden.push(o.optionKey);
    });
    if (this.state.whatsOn) {
      hidden.push('whatsOn');
    }
    if (this.state.marketplace) {
      hidden.push('marketplace');
    }
    if (this.state.people) {
      hidden.push('people');
    }
    if (this.state.more) {
      hidden.push('more');
    }
    if (this.state.profile) {
      hidden.push('profile');
    }
    if (this.state.infoPages) {
      hidden.push('infoPages');
    }
    if (this.state.maps) {
      hidden.push('maps');
    }
    if (this.state.surveys) {
      hidden.push('surveys');
    }
    if (this.state.importantContacts) {
      hidden.push('importantContacts');
    }
    if (this.state.feedback) {
      hidden.push('feedback');
    }
    if (this.state.whatsOnDatePicker) {
      hidden.push('whatsOnDatePicker');
    }
    if (this.state.whatsOnEmptyDates) {
      hidden.push('whatsOnEmptyDates');
    }
    if (this.state.eventRegistration) {
      hidden.push('eventRegistration');
    }
    if (this.state.eventComments) {
      hidden.push('eventComments');
    }
    if (this.state.eventNotifications) {
      hidden.push('eventNotifications');
    }
    if (this.state.serviceNotifications) {
      hidden.push('serviceNotifications');
    }
    if (this.state.facilityNotifications) {
      hidden.push('facilityNotifications');
    }
    if (this.state.surveyNotifications) {
      hidden.push('surveyNotifications');
    }
    if (this.state.offerNotifications) {
      hidden.push('offerNotifications');
    }
    if (this.state.kioskFooter) {
      hidden.push('kioskFooter');
    }
    if (this.state.tabTitles) {
      hidden.push('tabTitles');
    }
    if (!this.state.phonePeople) {
      notHidden.push('phonePeople');
    }
    if (!this.state.visitors) {
      notHidden.push('visitors');
    }

    // widget options
    extensionWidgetOptions.forEach((o) => {
      if (o && o.optionKey && o.type === 'toggle') settings[o.optionKey] = this.state[o.optionKey];
    });
    settings.UseGlobalEvents = this.state.useGlobalEvents;
    settings.UseEventImage = this.state.useEventImage;
    settings.EventFilter = this.state.eventFilter;
    settings.PeopleFilter = this.state.peopleFilter;
    settings.KioskVisitors = this.state.kioskVisitors;
    settings.UseGradientHeader = this.state.headerType === 'gradient';
    settings.HeaderType = this.state.headerType;
    if (this.state.headerType === 'pattern') {
      settings.HeaderPattern = this.state.selectedPattern;
    }
    settings.FooterType = this.state.footerType;
    settings.FooterColour = this.state.footerColour;
    settings.PatternColour = this.state.usePatternColour ? this.state.patternColour : null;
    settings.UseExpandedFeatured = this.state.useExpandedFeatured;

    // branding colours
    if (branding.MainBrandingColour !== this.state.colour) {
      branding.MainBrandingColour = this.state.colour;
      branding.LightBrandingColour = '#' + tinycolor(pctBtwnColours('#fff', this.state.colour, 0.6)).toHex();
      branding.DarkBrandingColour = '#' + tinycolor(pctBtwnColours(this.state.colour, '#000', 0.8)).toHex();
    }

    // logo
    if (!_.isEmpty(this.state.logo)) {
      branding.Logo = this.state.logo;
      settings.UseHeaderLogo = true;
    } else {
      settings.UseHeaderLogo = false;
      branding.Logo = null;
    }

    // new tab settings flow
    const tabSettings = [...this.state.tabSettings];
    tabSettings.forEach((t) => {
      t.icon = this.getTabKeyFromPage(t);
    });
    settings.TabSettings = tabSettings;

    if (onlyBranding) {
      const defSettings = this.state.site ? this.state.site.Settings || {} : {};
      return {
        Settings: {
          ...defSettings,
          UseHeaderLogo: settings.UseHeaderLogo,
          HeaderType: settings.HeaderType,
          HeaderPattern: settings.HeaderPattern,
          FooterType: settings.FooterType,
          FooterColour: settings.FooterColour,
          PatternColour: settings.PatternColour,
        },
        siteName: this.state.siteNameInput,
        Branding: branding,
      };
    }

    return { Hidden: hidden, NotHidden: notHidden, Settings: settings, Branding: branding, siteName: this.state.siteNameInput };
  }

  handleCancel = () => {
    if (this.state.anyChanges && !window.confirm('You have unsaved changes. Are you sure you want to continue?')) {
      return;
    }
    this.props.history.push('/');
  };

  handleSaveOptions = () => {
    const data = this.compileData(true);

    if (!this.props.isNew) {
      typeActions
        .editSiteSettings(this.props.auth.site, data)
        .then((res) => {})
        .catch((error) => {
          console.log('error', error);
        });
    }

    this.setState({
      site: { ...this.state.site, ...data, optionsChanged: false },
    });
    this.selectTab('tab0');
  };

  handleSubmit = () => {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({
      submitting: true,
      showWarnings: false,
    });

    if (this.props.isInterface) {
      const settings = this.compileData().Settings.TabSettings;
      typeActions.saveInterface(this.props.auth.site, 'app', this.state.interfaceTitle, settings, this.state.interfaceId).then((res) => {
        this.setState({
          interfaceId: res.data.result.Id,
        });
        const urlParams = getUrlParams();
        if (!_.isEmpty(urlParams.attach)) {
          typeActions.attachInterface(this.props.auth.site, urlParams.attach, res.data.result.Id).then((res) => {
            this.setState({
              submitting: false,
              success: true,
              anyChanges: false,
            });
          });
        } else {
          this.setState({
            submitting: false,
            success: true,
            anyChanges: false,
          });
        }
      });
    } else if (this.props.isNew) {
      typeActions.createNewSite(this.state.siteNameInput, this.compileData()).then((res) => {
        this.props.refreshAuthUser(res.data.site.Id);
        this.props.setAuthLocation(res.data.site.Id);
        this.setState({
          submitting: false,
          success: true,
          anyChanges: false,
        });
        setTimeout(() => {
          this.props.history.push('/');
        });
      });
    } else {
      typeActions
        .editSiteSettings(this.props.auth.site, this.compileData())
        .then((res) => {
          this.props.refreshAuthUser(this.props.auth.site);
          if (this.props.signup) {
            this.setState({
              submitting: false,
              signupComplete: true,
            });
          } else {
            this.setState({
              submitting: false,
              success: true,
              anyChanges: false,
            });
          }
        })
        .catch((error) => {
          console.log('error', error);
          this.setState({
            submitting: false,
            success: false,
          });
        });
    }
  };

  closeSuccessPopup = () => {
    this.setState({
      success: false,
    });
    window.location.reload();
  };

  onToggleGridLayout = (page, isGridLayout) => {
    page.template = isGridLayout ? 'grid' : undefined;
    const newState = {
      tabSettings: this.state.tabSettings,
      anyChanges: true,
    };
    if (!isGridLayout) {
      newState.gridPageCache = {
        widgets: [...page.widgets],
        widgetOptions: [...page.widgetOptions],
      };
      page.widgets = page.widgets.slice(0, 3);
      page.widgetOptions = page.widgetOptions.slice(0, 3);
    } else if (this.state.gridPageCache && this.state.gridPageCache.widgets.length > 3) {
      // restore previously selected grid features
      this.state.gridPageCache.widgets.forEach((w, i) => {
        if (i > 2 && !_.includes(page.widgets, w)) {
          page.widgets.push(w);
          page.widgetOptions.push(this.state.gridPageCache[i]);
        }
      });
      newState.gridPageCache = undefined;
    }
    this.setState(newState);
  };

  onToggleBlockBackground = (page, isBlock) => {
    page.backgroundType = isBlock ? 'block' : 'white';
    this.setState({
      tabSettings: [...this.state.tabSettings],
      anyChanges: true,
    });
  };

  renderPageButton(page) {
    if (!page.isEnabled) {
      return (
        <Button buttonType="outlinedGrey" compact onClick={this.onTogglePage.bind(this, page, true)} isActive leftIcon="eye">
          Restore page
        </Button>
      );
    }
    return (
      <Button buttonType="outlinedGrey" compact onClick={this.onTogglePage.bind(this, page, false)} isActive leftIcon="eye-slash">
        Hide page
      </Button>
    );
  }

  renderIconButton(page) {
    // if (page.type === 'home') {
    //   return null;
    // }
    const icon = this.getTabKeyFromPage(page);
    return (
      <div className="featurePicker_iconButton" onClick={this.openIconSelector}>
        <div className="featurePicker_iconButton_iconContainer">
          <SVGIcon icon={icon} colour={this.state.colour} />
        </div>
      </div>
    );
  }

  renderPageTitleInput(page) {
    return (
      <GenericInput
        id={`titleInput_${page.key}`}
        type="text"
        placeholder="Page title"
        value={page.tabTitle}
        onChange={(e) => this.handleTabTitleChange(page.key, e.target.value)}
        className="featurePicker_titleInput"
        inputStyle={{ color: this.state.colour }}
      />
    );
  }

  renderWidgetOptionText(option) {
    return (
      <div key={option.optionKey}>
        <GenericInput
          label={option.title}
          id={option.optionKey}
          type="text"
          value={this.getWidgetOptionText(this.state.optionsOpen.tabNumber, this.state.optionsOpen.widgetIndex, option.optionKey)}
          placeholder={option.placeholder}
          onChange={(e) =>
            this.handleOptionTextChange(e, this.state.optionsOpen.tabNumber, this.state.optionsOpen.widgetIndex, option.optionKey)
          }
          alwaysShowLabel
        />
      </div>
    );
  }

  renderWidgetOptionToggle(option) {
    return (
      <div
        key={option.optionKey}
        className={`featurePicker_option featurePicker_option-${option.optionKey}${
          !!this.state[option.optionKey] === !option.inverseOption ? ' featurePicker_option-selected' : ''
        }`}
        onClick={this.toggleOption.bind(this, option)}
      >
        <div className="featurePicker_mainSection_tile_inner">
          <div className="featurePicker_mainSection_tile_footer">
            <div className="featurePicker_mainSection_tile_footer_inner">
              <FontAwesome name="check" className="featurePicker_mainSection_tile_footer_tick" />
              <p className="featurePicker_mainSection_tile_footer_text">{option.title}</p>
            </div>
            <p className="featurePicker_mainSection_tile_footer_description">{option.description}</p>
          </div>
          <div className="featurePicker_mainSection_tile_image">
            <div className="featurePicker_mainSection_tile_image_inner"></div>
          </div>
        </div>
      </div>
    );
  }

  renderWidgetOptions() {
    return _.map(this.getWidgetOptions(this.state.optionsOpen.type, this.state.optionsOpen.widget, this.state.selectedTab), (o) => {
      if (o.type === 'text') return this.renderWidgetOptionText(o);
      return this.renderWidgetOptionToggle(o);
    });
  }

  renderTilePickerOptions(type, widget, tabNumber, widgetIndex) {
    if (type === 'main') {
      return (
        <Button
          className="featurePicker_mainSection_tile_optionsButton"
          buttonType="outlinedAction"
          onClick={(e) => {
            this.openOptions(type, widget, tabNumber, widgetIndex, e);
          }}
          isActive
          compact
          leftIcon="cog"
        >
          Settings
        </Button>
      );
    }
    return (
      <div
        className="featurePicker_mainSection_tile_options"
        onClick={(e) => {
          this.openOptions(type, widget, tabNumber, widgetIndex, e);
        }}
      >
        <FontAwesome name="cog" className="featurePicker_mainSection_tile_options_icon" />
      </div>
    );
  }

  renderIconSelector(page) {
    const largeMenu = page.type !== 'menu';
    const iconOptions = page.type === 'menu' ? this.moreIconOptions : this.iconOptions;
    return (
      <div className="relative">
        <div className={`featurePicker_iconSection${largeMenu ? ' featurePicker_iconSection-large' : ''}`}>
          {iconOptions.map((key, index) => {
            return (
              <div
                className={`featurePicker_iconOption${index % 4 === 3 ? ' featurePicker_iconOption-4thCol' : ''}`}
                key={key}
                onClick={() => {
                  this.selectIcon(page, key);
                }}
              >
                <SVGIcon icon={key} colour={page.icon === key ? this.state.colour : TEXT_LIGHT} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderWidget(w, page) {
    const tabNumber = this.state.tabSettings.indexOf(page);
    const index = page.widgets.indexOf(w);
    const isEditingTitle = this.state.isEditingWidgetTitle === `${tabNumber}_${index}`;
    const widgetTitle = this.renderWidgetTitle(tabNumber, index, w, isEditingTitle);
    const optionCount = this.getWidgetOptionCount('widget', w, tabNumber);
    const classes = this.getWidgetTileClasses(
      w,
      optionCount,
      page.widgets,
      20,
      page.widgets.length,
      index,
      isEditingTitle,
      this.isPageGrid(page),
    );
    const onSelect = this.selectWidgetForPage.bind(this, w, page, true);
    const onDeselect = (e) => {
      this.selectWidgetForPage(w, page, false, e);
    };

    return (
      <div className={classes} onClick={onSelect} key={w}>
        <div className="featurePicker_mainSection_tile_inner">
          {getExtensionViewWidget(w, () => (
            <div className="featurePicker_mainSection_tile_image_inner" />
          ))}
          {widgetTitle}
          {this.renderTilePickerOptions('widget', w, tabNumber, index)}
          <div className="featurePicker_mainSection_tile_image_cancelButton" onClick={onDeselect}>
            <SVGIcon colour={COLOUR_DUSK_LIGHTER} icon="close" className="featurePicker_mainSection_tile_image_cancelButton_icon" />
          </div>
          <span className="featurePicker_mainSection_tile_addIcon">+</span>
        </div>
        <div className="featurePicker_mainSection_tile_reorder">
          <div
            className="featurePicker_mainSection_tile_reorder_button featurePicker_mainSection_tile_reorder_button-up"
            onClick={(e) => {
              if (this.isPageGrid(page)) {
                this.moveWidgetDown(tabNumber, w, e);
              } else {
                this.moveWidgetUp(tabNumber, w, e);
              }
            }}
          >
            <FontAwesome name="angle-up" className="featurePicker_mainSection_tile_reorder_icon" />
          </div>
          <div
            className="featurePicker_mainSection_tile_reorder_button featurePicker_mainSection_tile_reorder_button-down"
            onClick={(e) => {
              if (this.isPageGrid(page)) {
                this.moveWidgetUp(tabNumber, w, e);
              } else {
                this.moveWidgetDown(tabNumber, w, e);
              }
            }}
          >
            <FontAwesome name="angle-down" className="featurePicker_mainSection_tile_reorder_icon" />
          </div>
        </div>
      </div>
    );
  }

  renderPageWidgetSelector(page) {
    // if (page.widgets.length === 0) return null;
    const widgets = _.filter(this.widgetKeys, (w) => {
      return !_.includes(page.widgets, w);
    });
    if (widgets.length === 0) return null;

    return (
      <div className="featurePicker_mainSection_widgetContainer featurePicker_mainSection_widgetContainer-boxed">
        <p className="featurePicker_mainSection_widgetContainer_title">
          You can add features to any page <span className="featurePicker_mainSection_widgetContainer_title-optional">(optional)</span>
        </p>
        {widgets.map((w) => {
          if (_.includes(page.widgets, w)) {
            return null;
          }
          return this.renderWidget(w, page);
        })}
      </div>
    );
  }

  renderWidgetTitle(tabNumber, widgetIndex, widget, isEditing) {
    const widgetKey = `${tabNumber}_${widgetIndex}`;
    const page = this.state.tabSettings[tabNumber];
    const rawTitle = this.getWidgetTitleFromPage(page, widgetIndex, '');
    const title = this.getWidgetTitleFromPage(page, widgetIndex, widget);
    return (
      <div className="featurePicker_mainSection_tile_footer_inner">
        {!isEditing && (
          <div className="featurePicker_mainSection_tile_title">
            <p className="featurePicker_mainSection_tile_footer_text">{title}</p>
            <FontAwesome
              name="pencil"
              className="featurePicker_mainSection_tile_footer_edit"
              onClick={() => {
                this.startEditingWidgetTitle(widgetKey);
              }}
            />
          </div>
        )}
        {isEditing && (
          <div className="flex-1 flex-reverse">
            <Button inline compact buttonType="primary" onClick={this.endEditingWidgetTitle} isActive={true}>
              Save
            </Button>
            <div className="flex-1 paddingRight-10">
              <GenericInput
                inputClass="featurePicker_mainSection_tile_footer_text"
                inputStyle={{ padding: 0 }}
                style={{ marginBottom: 0 }}
                textWrapperStyle={{ marginBottom: 0 }}
                id={`${widgetKey}_input`}
                type="text"
                value={rawTitle}
                onChange={(e) => this.handleWidgetTitleChange(tabNumber, widgetIndex, e.target.value)}
                maxLength={20}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  renderMainTileOption(widget, page) {
    const selectedLength = page.widgets.length;
    const selection = !_.isEmpty(page.widgets) ? page.widgets[0] : null;
    const tabNumber = this.state.tabSettings.indexOf(page);
    const optionCount = this.getWidgetOptionCount('main', widget, tabNumber);
    const isEditingTitle = this.state.isEditingWidgetTitle === `${tabNumber}_0`;

    return (
      <div
        className={this.getMainTileClasses(widget, selection, optionCount, selectedLength)}
        onClick={this.selectMainTile.bind(this, widget, tabNumber, true)}
        key={`${tabNumber}_${widget}`}
      >
        <div className="featurePicker_mainSection_tile_inner">
          <div className="featurePicker_mainSection_tile_footer">
            {this.renderWidgetTitle(tabNumber, 0, widget, isEditingTitle)}
            {this.renderTilePickerOptions('main', widget, tabNumber, 0)}
          </div>
          <div className="featurePicker_mainSection_tile_image">
            {getExtensionViewFull(widget, page, () => (
              <div className="featurePicker_mainSection_tile_image_inner" />
            ))}
            <div
              className="featurePicker_mainSection_tile_image_cancelButton"
              onClick={(e) => {
                this.selectMainTile(widget, tabNumber, false, e);
              }}
            >
              <SVGIcon colour={COLOUR_DUSK_LIGHTER} icon="close" className="featurePicker_mainSection_tile_image_cancelButton_icon" />
            </div>
            <div className="featurePicker_mainSection_tile_info">
              <p className="featurePicker_mainSection_tile_info_title">Feature Details</p>
              <p className="featurePicker_mainSection_tile_info_text">{this.getMainTileInfo(widget)}</p>
            </div>
          </div>
        </div>
        <div className="featurePicker_mainSection_tile_reorder">
          <div
            className="featurePicker_mainSection_tile_reorder_button featurePicker_mainSection_tile_reorder_button-up"
            onClick={(e) => {
              this.moveWidgetUp(tabNumber, widget, e);
            }}
          >
            <FontAwesome name="angle-up" className="featurePicker_mainSection_tile_reorder_icon" />
          </div>
          <div
            className="featurePicker_mainSection_tile_reorder_button featurePicker_mainSection_tile_reorder_button-down"
            onClick={(e) => {
              this.moveWidgetDown(tabNumber, widget, e);
            }}
          >
            <FontAwesome name="angle-down" className="featurePicker_mainSection_tile_reorder_icon" />
          </div>
        </div>
      </div>
    );
  }

  renderMainTilePicker(page) {
    const showGrid = true; //!_.isEmpty(page.widgets);
    const source = [...page.widgets];
    if (!this.isPageGrid(page)) {
      source.reverse();
    }
    return (
      <div className={`featurePicker_mainSection${showGrid ? ' featurePicker_mainSection-grid' : ''}`}>
        <div className="featurePicker_mainSection_inner">
          <Reorder
            reorderId="selectedWidgets"
            lock="horizontal"
            mouseHoldTime={0}
            onReorder={this.onReorderWidgets.bind(this, page)}
            autoScroll
            disableContextMenus
            disabled
            //disabled={page.widgets.length < 2}
            placeholderClassName="featurePicker_mainSection_tile-placeholderDrop"
            draggedClassName="featurePicker_mainSection_tile-dragged"
          >
            {source.map((w, i) => {
              if (i === page.widgets.length - 1 && page.widgets.length < 3 && !this.isPageGrid(page)) {
                return this.renderMainTileOption(w, page);
              }
              return this.renderWidget(w, page);
            })}
          </Reorder>
          {/* )} */}
        </div>
        {showGrid && this.renderPageWidgetSelector(page)}
      </div>
    );
  }

  renderListOption(o) {
    const isSelected = o.inverseOption === !this.state[o.optionKey];
    return (
      <div
        key={o.optionKey}
        className={`featurePicker_listOption${isSelected ? ' featurePicker_listOption-selected' : ''}`}
        onClick={this.toggleOption.bind(this, o)}
      >
        <div className="featurePicker_listOption_inner">
          <FontAwesome name="check" className="featurePicker_listOption_tick" />
          <p className="featurePicker_listOption_text">{o.displayName}</p>
        </div>
      </div>
    );
  }

  renderListPicker(page) {
    return (
      <div className="featurePicker_mainSection featurePicker_mainSection-grid" style={{ paddingLeft: page ? 10 : 0, paddingRight: 0 }}>
        <div className="flex-1">
          {this.moreOptions.map((o) => {
            return this.renderListOption(o);
          })}
        </div>
      </div>
    );
  }

  renderFooterPicker() {
    if (!isTheBest(this.props.auth, true)) {
      return null;
    }
    return (
      <div className="marginTop-20">
        <Button buttonType="boxed" buttonClassName="button--boxed-dusk" onClick={this.toggleFooterPopup.bind(this, true)} isActive>
          Customise Footer
        </Button>
      </div>
    );
  }

  renderColourPicker() {
    return (
      <div className="colourPicker">
        <div
          className="colourPicker_button"
          onClick={() => {
            this.setState({ colourPickerOpen: !this.state.colourPickerOpen });
          }}
        >
          <p className="colourPicker_text">Choose from Colour Picker</p>
          <div className="colourPicker_rainbow">
            <div
              className="colourPicker_rainbow_circle"
              style={{
                top: `${this.state.colourCircleTop}%`,
                left: `${this.state.colourCircleLeft}%`,
              }}
            ></div>
          </div>
        </div>
        <div
          className="colourPicker_button"
          onClick={() => {
            this.setState({ colourQuickChoicesOpen: !this.state.colourQuickChoicesOpen, selectedQuickChoice: 'vibrant' });
          }}
        >
          <p className="colourPicker_text">Choose from Quick Choices</p>
          <div className="colourPicker_previewCircles">
            <div className="colourPicker_previewCircles_circle colourPicker_previewCircles_circle-1"></div>
            <div className="colourPicker_previewCircles_circle colourPicker_previewCircles_circle-2"></div>
            <div className="colourPicker_previewCircles_circle colourPicker_previewCircles_circle-3"></div>
          </div>
        </div>
      </div>
    );
  }

  renderLogoSection() {
    if (this.state.logo) {
      return (
        <div className="marginTop-20 flex-reverse">
          <Button buttonType="boxed" buttonClassName="button--boxed-dusk" onClick={this.onLogoUpdated.bind(this, null)} isActive>
            Remove logo
          </Button>
          <div className="featurePicker_logoPreview" style={{ backgroundImage: `url('${this.state.logo}')` }} />
        </div>
      );
    }
    return (
      <div className="marginTop-20">
        <Button buttonType="boxed" buttonClassName="button--boxed-dusk" onClick={this.toggleLogoPopup.bind(this, true)} isActive>
          Upload logo
        </Button>
      </div>
    );
  }

  renderPageApp() {
    return (
      <div className="featurePickerPage">
        <div className="flex">
          <div className="featurePicker_appOptionsLeft">
            <div className="featurePicker_titleContainer">
              <div className="featurePicker_sectionTitle">
                <img
                  className="featurePicker_sectionTitle_image"
                  alt="features"
                  src="https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/3cc483f6441cb0395c6a9526e8/mainitem.png"
                />
                <p className="featurePicker_sectionTitle_text">App Information</p>
              </div>
            </div>
            <div className="featurePicker_nameLogoContainer">
              <GenericInput
                className="genericInputContainer-largeLabel"
                id="siteNameInput"
                type="text"
                value={this.state.siteNameInput}
                onChange={(e) => this.handleChange(e)}
                placeholder="Your Community Name here"
                label="Name"
                alwaysShowLabel
              />
              {this.renderLogoSection()}
            </div>
            {this.renderColourPicker()}
            {this.renderFooterPicker()}
          </div>
          <div className="flex-1 paddingLeft-40">
            <div className="featurePicker_titleContainer">
              <div className="featurePicker_sectionTitle">
                <img
                  className="featurePicker_sectionTitle_image"
                  alt="style"
                  src="https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/5d92f926440cb4df438d52996d/paintbrush.png"
                />
                <p className="featurePicker_sectionTitle_text">Header Style</p>
              </div>
            </div>
            <div>
              <div
                className={this.getHeaderTileClasses('white')}
                onClick={() => {
                  this.changeHeader('white');
                }}
              >
                <div className="featurePicker_mainSection_tile_inner">
                  <div className="featurePicker_mainSection_tile_footer">
                    <div className="featurePicker_mainSection_tile_footer_inner">
                      <FontAwesome name="check" className="featurePicker_mainSection_tile_footer_tick" />
                      <p className="featurePicker_mainSection_tile_footer_text">White</p>
                    </div>
                  </div>
                  <div className="featurePicker_mainSection_tile_image">
                    <div className="featurePicker_mainSection_tile_image_inner"></div>
                  </div>
                </div>
              </div>
              <div
                className={this.getHeaderTileClasses('block')}
                onClick={() => {
                  this.changeHeader('block');
                }}
              >
                <div className="featurePicker_mainSection_tile_inner">
                  <div className="featurePicker_mainSection_tile_footer">
                    <div className="featurePicker_mainSection_tile_footer_inner">
                      <FontAwesome name="check" className="featurePicker_mainSection_tile_footer_tick" />
                      <p className="featurePicker_mainSection_tile_footer_text">Block</p>
                    </div>
                  </div>
                  <div className="featurePicker_mainSection_tile_image">
                    <div className="featurePicker_mainSection_tile_image_inner"></div>
                  </div>
                </div>
              </div>
              <div
                className={this.getHeaderTileClasses('gradient')}
                onClick={() => {
                  this.changeHeader('gradient');
                }}
              >
                <div className="featurePicker_mainSection_tile_inner">
                  <div className="featurePicker_mainSection_tile_footer">
                    <div className="featurePicker_mainSection_tile_footer_inner">
                      <FontAwesome name="check" className="featurePicker_mainSection_tile_footer_tick" />
                      <p className="featurePicker_mainSection_tile_footer_text">Gradient</p>
                    </div>
                  </div>
                  <div className="featurePicker_mainSection_tile_image">
                    <div className="featurePicker_mainSection_tile_image_inner"></div>
                  </div>
                </div>
              </div>
              <div
                className={this.getHeaderTileClasses('pattern')}
                onClick={() => {
                  this.changeHeader('pattern');
                }}
              >
                <div className="featurePicker_mainSection_tile_inner">
                  <div className="featurePicker_mainSection_tile_footer">
                    <div className="featurePicker_mainSection_tile_footer_inner">
                      <Button
                        inline
                        buttonType="primary"
                        className="featurePicker_mainSection_tile_footer_settingsButton"
                        onClick={this.openPatternOptions}
                        isActive={true}
                      >
                        Settings
                      </Button>
                      <FontAwesome name="check" className="featurePicker_mainSection_tile_footer_tick" />
                      <p className="featurePicker_mainSection_tile_footer_text">Pattern</p>
                    </div>
                  </div>
                  <div className="featurePicker_mainSection_tile_image">
                    <div className="featurePicker_mainSection_tile_image_inner"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderGridToggle(page) {
    return (
      <div className="flex">
        <div className="featurePicker_gridToggle">
          <Switch
            className="featurePicker_gridToggle_toggle"
            checked={this.isPageGrid(page)}
            onChange={(isGrid) => this.onToggleGridLayout(page, isGrid)}
            onColor={COLOUR_BRANDING_MAIN}
            offColor={COLOUR_DUSK_LIGHTER}
            checkedIcon={false}
            uncheckedIcon={false}
            height={26}
            width={50}
          />
          <p className="featurePicker_gridToggle_label">Grid Mode</p>
        </div>
        {this.isPageGrid(page) && (
          <div className="featurePicker_gridToggle">
            <Switch
              className="featurePicker_gridToggle_toggle"
              checked={this.getBackgroundType(page) === 'block'}
              onChange={(isBlock) => this.onToggleBlockBackground(page, isBlock)}
              onColor={COLOUR_BRANDING_MAIN}
              offColor={COLOUR_DUSK_LIGHTER}
              checkedIcon={false}
              uncheckedIcon={false}
              height={26}
              width={50}
            />
            <p className="featurePicker_gridToggle_label">Coloured Background</p>
          </div>
        )}
      </div>
    );
  }

  renderBlankPage(page) {
    return (
      <div className="featurePickerPage">
        <div className="flex-reverse flex-center height-40 marginBottom-10">
          {page.type !== 'home' && this.renderPageButton(page)}
          <div className="flex-1 flex flex-center">
            {this.renderIconButton(page)}
            {page.type === 'home' && this.renderGridToggle(page)}
            {page.type !== 'home' && this.renderPageTitleInput(page)}
          </div>
        </div>
        {this.state.iconSelectorOpen && this.renderIconSelector(page)}
        {this.renderMainTilePicker(page)}
      </div>
    );
  }

  renderMenuPage(page) {
    return (
      <div className="featurePickerPage">
        <div className="flex-reverse flex-center height-40 marginBottom-10">
          {this.renderPageButton(page)}
          <div className="flex-1">{this.renderIconButton(page)}</div>
        </div>
        {this.state.iconSelectorOpen && this.renderIconSelector(page)}
        {this.renderListPicker(page)}
      </div>
    );
  }

  renderPage() {
    switch (this.state.selectedTab) {
      case 'app':
        return this.renderPageApp();
      default:
        const page = _.find(this.state.tabSettings, (t) => {
          return t.key === this.state.selectedTab;
        });
        switch (page.type) {
          case 'home':
          case 'blank':
            return this.renderBlankPage(page);
          case 'menu':
            return this.renderMenuPage(page);
          default:
            break;
        }
    }
  }

  renderPreviewFooter() {
    if (
      (this.state.tabSettings.length === 1 ||
        _.filter(this.state.tabSettings, (t) => {
          return t.isEnabled;
        }).length === 1) &&
      !this.state.footerPopupOpen
    ) {
      return null;
    }
    const textColour = this.state.footerType !== 'white' ? '#fff' : TEXT_LIGHT;
    const selectedColour = this.state.footerType !== 'white' ? '#fff' : this.state.colour;
    // const selectedBGColour = this.state.footerType !== 'white' ? 'rgba(255,255,255,0.2)' : '#fff';
    return (
      <div className={`featurePickerPreview_footer featurePickerPreview_footer-${this.state.footerType}`}>
        {this.state.footerType === 'block' && (
          <div className="featurePickerPreview_footer_background" style={{ backgroundColor: this.state.footerColour }}></div>
        )}
        {this.state.footerType === 'gradient' && (
          <div className="featurePickerPreview_footer_background" style={{ backgroundColor: this.state.footerColour }}></div>
        )}
        {this.state.tabSettings.map((t) => {
          if (!t.isEnabled) {
            return null;
          }
          return (
            <div
              className={`featurePickerPreview_footer_icon${
                this.state.selectedTab === t.key ? ' featurePickerPreview_footer_icon-selected' : ''
              }`}
              key={t.key}
              // style={{ backgroundColor: this.state.selectedTab === t.key ? selectedBGColour : COLOUR_TRANSPARENT }}
            >
              <div className="featurePickerPreview_footer_icon_svg">
                <SVGIcon icon={this.getTabKeyFromPage(t)} colour={this.state.selectedTab === t.key ? selectedColour : textColour} />
              </div>
              <p
                className="featurePickerPreview_footer_icon_title"
                style={{ color: this.state.selectedTab === t.key ? selectedColour : textColour }}
              >
                {t.tabTitle}
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  renderFeaturePreviewFull = (widget, page, widgetTitle, backgroundColor, phonePeople) => {
    const options = {};
    extensionWidgetOptions.forEach((o) => {
      if (o.optionKey) options[o.optionKey] = this.state[o.optionKey];
    });
    return getExtensionPreviewFull(widget, page, widgetTitle, backgroundColor, options, () => {
      let extraClasses = '';
      switch (widget) {
        case 'people':
          if (!phonePeople) extraClasses = ' featurePickerPreview_tile-people-allowCall';
          break;
        default:
          break;
      }

      return (
        <div key={widget}>
          <p className="featurePickerPreview_tile_title">{widgetTitle}</p>
          <div
            key={widget}
            className={`featurePickerPreview_tile featurePickerPreview_tile-${widget}${extraClasses}`}
            style={{ backgroundColor }}
          ></div>
        </div>
      );
    });
  };

  renderPreviewMainPage(key, widgetTitle, tabTitle, page) {
    switch (key) {
      case 'events':
        return (
          <div className="featurePickerPreview_inner">
            <div
              className={`featurePickerPreview_headerWhatsOn${
                this.state.headerType === 'gradient' ? ' featurePickerPreview_headerWhatsOn-gradient' : ''
              }`}
              style={{ backgroundColor: this.state.colour }}
            ></div>
            <div className="featurePickerPreview_main">
              {!this.state.whatsOnDatePicker && (
                <div className="featurePickerPreview_datePicker" style={{ backgroundColor: this.state.colour }}></div>
              )}
              <div
                className={`featurePickerPreview_events${this.state.whatsOnEmptyDates ? ' featurePickerPreview_events-noEmpty' : ''}`}
                style={{ backgroundColor: this.state.colour }}
              ></div>
            </div>
            {this.renderPreviewFooter()}
          </div>
        );
      default:
        return (
          <div className="featurePickerPreview_inner">
            <div
              className={`featurePickerPreview_headerGeneric featurePickerPreview_headerGeneric-${this.state.headerType}`}
              style={{ backgroundColor: this.state.colour }}
            >
              {this.state.headerType === 'pattern' && (
                <div className="featurePickerPreview_header_background" style={this.getHeaderStyle()}></div>
              )}
              <div className="featurePickerPreview_header_image"></div>
              <p className="featurePickerPreview_headerGeneric_text">{tabTitle}</p>
            </div>
            {this.renderFeaturePreviewFull(key, page, widgetTitle, this.state.colour, this.state.phonePeople)}
            {this.renderPreviewFooter()}
          </div>
        );
    }
  }

  renderGridPreview(page) {
    const { colour } = this.state;
    return (
      <div className="featurePickerPreview_grid">
        {page.widgets.map((w, i) => {
          const title = this.getWidgetTitleFromPage(page, i, page.widgets[i]);
          const icon = getExtensionPreviewGridIcon(w, colour, () => <SVGIcon icon={`line_${w}`} colour="#fff" />);

          return (
            <div key={w} className={`featurePickerPreview_gridItem`}>
              <div className="featurePickerPreview_gridItem_inner">
                <div className="featurePickerPreview_gridItem_titleContainer">
                  <p className="featurePickerPreview_gridItem_title">{title}</p>
                </div>
                <div className="featurePickerPreview_gridItem_iconCircle" style={{ backgroundColor: colour }}>
                  <div className="featurePickerPreview_gridItem_icon">{icon}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderFeaturePreviewWidget = (widget, page, widgetTitle, backgroundColor, useEventImage) => {
    return getExtensionPreviewWidget(widget, page, widgetTitle, backgroundColor, () => {
      let extraClasses = '';
      switch (widget) {
        case 'events':
          if (!useEventImage) extraClasses = ' featurePickerPreview_tile-eventsWidget-compressed';
          break;
        default:
          break;
      }

      return (
        <div
          key={widget}
          className={`featurePickerPreview_tile featurePickerPreview_tile-${widget}Widget${extraClasses}`}
          style={{ backgroundColor }}
        >
          <p className="featurePickerPreview_tile_title featurePickerPreview_tile_title-widget">{widgetTitle}</p>
        </div>
      );
    });
  };

  renderBlankPreviewPage(page) {
    if (page.widgets.length === 1) {
      return this.renderPreviewMainPage(page.widgets[0], this.getWidgetTitleFromPage(page, 0, page.widgets[0]), page.tabTitle, page);
    }
    return (
      <div
        className="featurePickerPreview_inner"
        style={this.getBackgroundType(page) === 'block' ? { backgroundColor: this.state.colour } : null}
      >
        <div
          className={`featurePickerPreview_headerGeneric featurePickerPreview_headerGeneric-${this.state.headerType}`}
          style={{ backgroundColor: this.state.colour }}
        >
          {this.state.headerType === 'pattern' && (
            <div className="featurePickerPreview_header_background" style={this.getHeaderStyle()}></div>
          )}
          <div className="featurePickerPreview_header_image"></div>
          <p className="featurePickerPreview_headerGeneric_text">{page.tabTitle}</p>
        </div>
        {this.isPageGrid(page)
          ? this.renderGridPreview(page)
          : page.widgets
              .slice(0)
              .reverse()
              .map((w, i) => {
                if (page.widgets.length === 2 && i === 1) {
                  return this.renderFeaturePreviewFull(
                    w,
                    page,
                    this.getWidgetTitleFromPage(page, page.widgets.length - 1 - i, w),
                    this.state.colour,
                    this.state.phonePeople,
                  );
                }
                return this.renderFeaturePreviewWidget(
                  w,
                  page,
                  this.getWidgetTitleFromPage(page, page.widgets.length - 1 - i, w),
                  this.state.colour,
                  this.state.useEventImage,
                );
              })}
        {this.renderPreviewFooter()}
      </div>
    );
  }

  renderMenuPreviewPage(page) {
    return (
      <div className="featurePickerPreview_inner">
        <div
          className={`featurePickerPreview_headerMore featurePickerPreview_headerMore-${this.state.headerType}`}
          style={{ backgroundColor: this.state.colour }}
        >
          {this.state.headerType === 'pattern' && (
            <div className="featurePickerPreview_header_background" style={this.getHeaderStyle()}></div>
          )}
          <div className="featurePickerPreview_header_image"></div>
        </div>
        <div className="featurePickerPreview_main">
          {this.moreOptions.map((o) => {
            return this.renderPreviewMoreEntry(o);
          })}
          <div className="featurePickerPreview_more_logout" style={{ backgroundColor: this.state.colour }}></div>
        </div>
        {this.renderPreviewFooter()}
      </div>
    );
  }

  renderPreviewMoreEntry(entry) {
    const isSelected = entry.inverseOption === !this.state[entry.optionKey];
    if (!isSelected) {
      return null;
    }
    return (
      <div key={entry.optionKey} className="featurePickerPreview_more_entry">
        <div className="featurePickerPreview_more_entry_inner">
          <FontAwesome name="angle-right" className="featurePickerPreview_more_entry_icon" style={{ color: this.state.colour }} />
          <p className="featurePickerPreview_more_entry_text">{entry.displayName}</p>
        </div>
      </div>
    );
  }

  renderPreviewAppPage() {
    return (
      <div className="featurePickerPreview_inner">
        <div className="featurePickerPreview_main featurePickerPreview_main-branding">
          {!_.isEmpty(this.state.logo) ? (
            <img src={this.state.logo} alt={this.state.siteNameInput} className="featurePickerPreview_main_logo" />
          ) : (
            <p className="featurePickerPreview_main_title" style={{ color: this.state.colour }}>
              {this.state.siteNameInput}
            </p>
          )}
        </div>
        <div className="featurePickerPreview_footer" style={{ backgroundColor: this.state.colour }}></div>
      </div>
    );
  }

  renderPreviewPage0(page) {
    let widgets = this.state.topWidgets;
    let main = this.state.mainWidget;
    let mainTitle = this.getWidgetTitle(main);
    let widgetTitles =
      widgets &&
      widgets.map((w) => {
        return this.getWidgetTitle(w);
      });
    const hasEmptyState = page && _.isEmpty(page.widgets) && this.state.tabSettings.length === 1 && !this.state.footerPopupOpen;
    if (page && page.widgets) {
      if (!_.isEmpty(page.widgets)) {
        main = page.widgets[0];
        mainTitle = this.getWidgetTitleFromPage(page, 0, main);
        widgets = page.widgets.slice(1).reverse();
        widgetTitles = widgets.map((w, i) => {
          return this.getWidgetTitleFromPage(page, page.widgets.length - 1 - i, w);
        });
      } else {
        main = null;
        widgets = [];
      }
    }

    const header = (
      <div
        className={`featurePickerPreview_header featurePickerPreview_header-${this.state.headerType}`}
        style={{
          backgroundColor: this.state.colour,
          borderBottomWidth: this.getBackgroundType(page) === 'block' && this.state.headerType === 'block' ? 0 : 1,
        }}
      >
        {this.state.headerType === 'pattern' && (
          <div className="featurePickerPreview_header_background" style={this.getHeaderStyle()}></div>
        )}
        <div className="featurePickerPreview_header_image"></div>
        {this.state.logo ? (
          <div className="featurePickerPreview_header_logoContainer">
            <div className="featurePickerPreview_header_logo" style={{ backgroundImage: `url('${this.state.logo}')` }} />
          </div>
        ) : (
          <p className="featurePickerPreview_header_text" style={{ color: this.state.headerType === 'white' ? this.state.colour : '#fff' }}>
            {this.state.siteNameInput}
          </p>
        )}
      </div>
    );

    if (this.isPageGrid(page)) {
      return (
        <div
          className="featurePickerPreview_inner"
          style={this.getBackgroundType(page) === 'block' ? { backgroundColor: this.state.colour } : null}
        >
          {header}
          {this.renderGridPreview(page)}
          {!hasEmptyState && this.renderPreviewFooter()}
          {hasEmptyState && (
            <div className="featurePickerPreview_homeEmpty">
              <p className="featurePickerPreview_homeEmpty_text" style={{ backgroundColor: this.state.colour }}>
                Your feature choices will go here
              </p>
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="featurePickerPreview_inner">
        {header}
        {main && (
          <div className="featurePickerPreview_main">
            {widgets.map((w, i) => {
              return this.renderFeaturePreviewWidget(w, page, widgetTitles[i], this.state.colour, this.state.useEventImage);
            })}
            {this.renderFeaturePreviewFull(main, page, mainTitle, this.state.colour, this.state.phonePeople)}
          </div>
        )}
        {!hasEmptyState && this.renderPreviewFooter()}
        {hasEmptyState && (
          <div className="featurePickerPreview_homeEmpty">
            <p className="featurePickerPreview_homeEmpty_text" style={{ backgroundColor: this.state.colour }}>
              Your feature choices will go here
            </p>
          </div>
        )}
      </div>
    );
  }

  renderPreviewPage() {
    if (this.state.selectedTab === 'app') {
      // if (this.state.patternOptionsOpen) {
      return this.renderPreviewPage0(this.state.tabSettings[0]);
      // }
      // return this.renderPreviewAppPage();
    }
    const page = _.find(this.state.tabSettings, (t) => {
      return t.key === this.state.selectedTab;
    });
    switch (page.type) {
      case 'home':
        return this.renderPreviewPage0(page);
      case 'blank':
        return this.renderBlankPreviewPage(page);
      case 'menu':
        return this.renderMenuPreviewPage(page);
      default:
        return null;
    }
  }

  renderPreview() {
    return <div className="featurePickerPreview">{this.renderPreviewPage()}</div>;
  }

  renderOptionsPopup() {
    if (!this.state.optionsOpen) {
      return null;
    }
    return (
      <Popup
        buttons={[
          {
            type: 'primary',
            onClick: this.openOptions.bind(this),
            isActive: true,
            text: 'Done',
          },
        ]}
        onClose={() => {
          this.openOptions();
        }}
        minWidth="80vw"
        hasPadding
      >
        <div className="featurePicker_popup_inner">
          {this.renderPreview()}
          <div className="featurePickerRight featurePickerRight-options">
            <div className="featurePicker_popup_titleContainer" style={{ paddingLeft: 10 }}>
              <span className="popup_title featurePicker_popup_title-faded">{this.getWidgetTitle(this.state.optionsOpen.widget)}</span>
              <FontAwesome name="angle-right" className="featurePicker_popup_titleIcon featurePicker_popup_title-faded" />
              <span className="popup_title">Options</span>
            </div>
            <div className="featurePicker_mainSection featurePicker_mainSection-grid">
              <div className="featurePicker_mainSection_widgetContainer">
                <div className="featurePicker_mainSection_empty">
                  <GenericInput
                    label="Empty state text"
                    id="emptyStateInput"
                    type="text"
                    value={this.getWidgetEmptyText(this.state.optionsOpen.tabNumber, this.state.optionsOpen.widgetIndex)}
                    placeholder={this.widgetInfoAll[this.state.optionsOpen.widget].emptyText}
                    onChange={(e) => this.handleEmptyTextChange(e, this.state.optionsOpen.tabNumber, this.state.optionsOpen.widgetIndex)}
                    maxLength={100}
                    alwaysShowLabel
                  />
                </div>
                {this.renderWidgetOptions()}
              </div>
            </div>
          </div>
        </div>
      </Popup>
    );
  }

  renderLogoPopup() {
    if (!this.state.logoPopupOpen) {
      return null;
    }
    return (
      <Popup
        buttons={[
          {
            type: 'primary',
            onClick: this.toggleLogoPopup.bind(this, false),
            isActive: true,
            text: 'Done',
          },
        ]}
        maxHeight={450}
        maxWidth={650}
        title="Upload logo"
        hasPadding
      >
        <div className="featurePicker_popup_inner" style={{ display: 'block' }}>
          <div className="marginTop-20 flex">
            <div className="marginRight-20">
              <ImageInput
                ref="imageInput"
                containerStyle={{ width: 300, height: 220 }}
                style={{ width: 300, height: 220 }}
                noCompress
                noMenu
                refreshCallback={this.onLogoUpdated.bind(this)}
                hasDefault={this.state.logo}
              />
            </div>
            <div className="flex-1">
              <p className="featurePickerLogoPopup_guidelinesTitle">Logo guidelines:</p>
              <p className="featurePickerLogoPopup_guidelinesText">
                &bull; Your logo works best in png format with a transparent background.
              </p>
              <p className="featurePickerLogoPopup_guidelinesText">
                &bull; If you use gradient header, a single coloured logo in white with a transparent background is recommended.
              </p>
            </div>
          </div>
        </div>
      </Popup>
    );
  }

  renderFooterPopup() {
    if (!this.state.footerPopupOpen) {
      return null;
    }
    return (
      <Popup
        buttons={[
          {
            type: 'primary',
            onClick: this.toggleFooterPopup.bind(this, false),
            isActive: true,
            text: 'Done',
          },
        ]}
        title="Customise footer"
        hasPadding
        maxHeight="95vh"
      >
        <div className="featurePicker_popup_inner featurePicker-scaled">
          {this.renderPreview()}
          <div className="featurePickerRight featurePickerRight-options">
            <div
              className={this.getFooterTileClasses('white')}
              onClick={() => {
                this.changeFooter('white');
              }}
            >
              <div className="featurePicker_mainSection_tile_inner">
                <div className="featurePicker_mainSection_tile_footer">
                  <div className="featurePicker_mainSection_tile_footer_inner">
                    <FontAwesome name="check" className="featurePicker_mainSection_tile_footer_tick" />
                    <p className="featurePicker_mainSection_tile_footer_text">White</p>
                  </div>
                </div>
                <div className="featurePicker_mainSection_tile_image">
                  <div className="featurePicker_mainSection_tile_image_inner"></div>
                </div>
              </div>
            </div>
            <div
              className={this.getFooterTileClasses('block')}
              onClick={() => {
                this.changeFooter('block');
              }}
            >
              <div className="featurePicker_mainSection_tile_inner">
                <div className="featurePicker_mainSection_tile_footer">
                  <div className="featurePicker_mainSection_tile_footer_inner">
                    <FontAwesome name="check" className="featurePicker_mainSection_tile_footer_tick" />
                    <p className="featurePicker_mainSection_tile_footer_text">Block</p>
                  </div>
                </div>
                <div className="featurePicker_mainSection_tile_image">
                  <div className="featurePicker_mainSection_tile_image_inner"></div>
                </div>
              </div>
            </div>
            {/* <div
              className={this.getFooterTileClasses('gradient')}
              onClick={() => {
                this.changeFooter('gradient');
              }}
            >
              <div className="featurePicker_mainSection_tile_inner">
                <div className="featurePicker_mainSection_tile_footer">
                  <div className="featurePicker_mainSection_tile_footer_inner">
                    <FontAwesome name="check" className="featurePicker_mainSection_tile_footer_tick" />
                    <p className="featurePicker_mainSection_tile_footer_text">Gradient</p>
                  </div>
                </div>
                <div className="featurePicker_mainSection_tile_image">
                  <div className="featurePicker_mainSection_tile_image_inner"></div>
                </div>
              </div>
            </div> */}

            {this.state.footerType !== 'white' && (
              <div className="flex flex-center flex-column marginTop-40">{this.renderColourQuickChoices('footer')}</div>
            )}
          </div>
        </div>
      </Popup>
    );
  }

  renderTemplate(t) {
    return (
      <div key={t.key} className="featurePicker_templateOption" onClick={this.selectTemplate.bind(this, t.key)}>
        <div className="featurePicker_templateOption_inner">
          <SVGIcon className="featurePicker_templateOption_icon" icon={t.icon} colour={COLOUR_BRANDING_ACTION} />
          <p className="featurePicker_templateOption_text">{t.title}</p>
        </div>
      </div>
    );
  }

  renderTemplateButton() {
    if (!this.state.selectedTemplate) {
      return (
        <Button inline buttonType="outlined" onClick={this.openTemplatePopup} isActive style={{ width: 150, marginTop: 10 }}>
          Use Template
        </Button>
      );
    }
    const selectedTemplate = templates[this.state.selectedTemplate];
    return (
      <div className="featurePicker_selectedTemplate">
        <div className="featurePicker_selectedTemplate_icon">
          <SVGIcon icon={selectedTemplate.icon} colour={COLOUR_BRANDING_ACTION} />
        </div>
        <span className="featurePicker_selectedTemplate_text">{selectedTemplate.title}</span>
        <div className="featurePicker_selectedTemplate_remove">
          <SVGIcon colour={COLOUR_DUSK} icon="close" onClick={this.selectTemplate.bind(this, 'blank')} />
        </div>
      </div>
    );
  }

  renderHeader() {
    if (this.state.selectedTab === 'app') {
      return (
        <div className="featurePicker_headerTray">
          <FontAwesome className="featurePicker_back" name="angle-left" onClick={this.cancelAppOptions} />
          <Text type="formTitleLarge" className="featurePicker_pageTitle">
            App Options
          </Text>
          <div className="flex flex-1 flex-center flex-reverse">
            <Button inline buttonType="primary" onClick={this.handleSaveOptions} isActive>
              Save Options
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div className="featurePicker_headerTray">
        <FontAwesome className="featurePicker_back" name="angle-left" onClick={this.handleCancel} />
        <Text type="formTitleLarge" className="featurePicker_pageTitle">
          Feature Picker
        </Text>
        <div className="flex flex-1 flex-center flex-reverse">
          <div className="flex flex-column flex-align-end">
            <Button inline buttonType="primary" onClick={this.handleSubmit} isActive={this.validateForm()} style={{ width: 150 }}>
              Save {this.props.isInterface ? 'View' : 'App'}
            </Button>
            {this.renderTemplateButton()}
          </div>
        </div>
      </div>
    );
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        {this.canCancel() && (
          <Button inline buttonType="tertiary" buttonStyle={{ marginRight: 20 }} onClick={this.handleCancel} isActive>
            Cancel
          </Button>
        )}
        {this.canAddNewPage() && (
          <div style={{ position: 'relative', display: 'inline-block', marginRight: 20 }}>
            <Button inline buttonType="outlined" onClick={this.toggleNewPage} isActive={true}>
              Add New Page
            </Button>
            {this.state.showNewPage && (
              <div className="featurePicker_addButton_menu">
                {_.filter(this.state.tabSettings, (t) => {
                  return t.type === 'blank';
                }).length < 3 && (
                  <p
                    className="featurePicker_addButton_menu_option"
                    onClick={() => {
                      return this.addNewPage('blank');
                    }}
                  >
                    Blank Page
                  </p>
                )}
                {!_.some(this.state.tabSettings, (t) => {
                  return t.type === 'menu';
                }) && (
                  <p
                    className="featurePicker_addButton_menu_option"
                    onClick={() => {
                      return this.addNewPage('menu');
                    }}
                  >
                    Menu Page
                  </p>
                )}
              </div>
            )}
          </div>
        )}
        <Button inline buttonType="primary" onClick={this.handleSubmit.bind(this)} isActive={this.validateForm()}>
          Save {this.props.isInterface ? 'View' : 'App'}
        </Button>
      </div>
    );
  }

  renderTabs() {
    return (
      <div className="featurePicker_tabs" id="featurePicker_tabs">
        <div className="featurePicker_tabsCaret" style={this.getCaretStyle()}></div>
        <Tabs
          onSelectTab={this.selectTab.bind(this)}
          selectedTab={this.state.selectedTab}
          tabs={this.getTabs()}
          // containerStyle={isHidden ? { visibility: 'hidden' } : null}
          handleTabTextChange={this.handleTabTitleChange}
        />
        {this.canAddNewPage() && (
          <Button
            inline
            buttonType="outlined"
            onClick={() => {
              this.addNewPage('blank');
            }}
            className="featurePicker_add"
            isActive
          >
            <span className="featurePicker_add_plus">+</span>Add New Page
          </Button>
        )}
        {this.renderMoreMenuToggle()}
      </div>
    );
  }

  renderMoreMenuToggle() {
    return (
      <div className="featurePicker_gridToggle">
        <Switch
          className="featurePicker_gridToggle_toggle"
          checked={this.isMoreEnabled()}
          onChange={this.onToggleMore}
          onColor={COLOUR_BRANDING_MAIN}
          offColor={COLOUR_DUSK_LIGHTER}
          checkedIcon={false}
          uncheckedIcon={false}
          height={26}
          width={50}
        />
        <p className="featurePicker_gridToggle_label">More Menu</p>
      </div>
    );
  }

  renderSuccessPopup() {
    if (!this.state.success) {
      return null;
    }
    return (
      <Popup
        title="Saved"
        subtitle="Your App is now ready!"
        hasPadding
        minWidth={400}
        minHeight={200}
        buttons={[
          {
            type: 'primary',
            onClick: this.closeSuccessPopup,
            isActive: true,
            text: 'Done',
          },
        ]}
      />
    );
  }

  renderInterfaceButton(iFace) {
    return (
      <div
        key={iFace && iFace.Id}
        className={`featurePickerInterfaces_button ${
          (iFace && iFace.Id === this.state.interfaceId) || (!iFace && _.isEmpty(this.state.interfaceId))
            ? 'featurePickerInterfaces_button-active'
            : ''
        }`}
        onClick={() => {
          this.selectInterface(iFace);
        }}
      >
        <p className="featurePickerInterfaces_button_text">{!_.isNull(iFace) ? iFace.Title : 'General'}</p>
        {!!iFace && (
          <div
            className="featurePickerInterfaces_button_edit"
            onClick={() => {
              this.editInterface(iFace);
            }}
          >
            Edit
          </div>
        )}
      </div>
    );
  }

  renderInterfaces() {
    if (this.props.signup) {
      return null;
    }
    return (
      <div className="featurePickerInterfaces">
        <FontAwesome name="plus-circle" className="featurePickerInterfaces_add" onClick={this.openAddInterface}></FontAwesome>
        {this.getAppInterfaces().map((iFace) => {
          return this.renderInterfaceButton(iFace);
        })}
        {this.renderInterfaceButton(null)}
        <p className="featurePickerInterfaces_title">
          APP
          <br />
          <span className="featurePickerInterfaces_title-sub">VIEWS</span>
        </p>
      </div>
    );
  }

  renderTemplatePopup() {
    if (!this.state.templatePopupOpen) {
      return null;
    }
    if (this.state.updatingTemplate) {
      return (
        <Popup>
          <Lottie key="config-lottie" height="400px" width="700px" config={this.state.lottieConfig} />
          <p className="signUp_lottieText">{this.state.updatingTemplateText}</p>
        </Popup>
      );
    }

    return (
      <Popup title="Select a Template" noDotTitle onClose={this.closeTemplatePopup} hasPadding>
        <div className="featurePicker_templates">
          {_.values(templates).map((t) => {
            return this.renderTemplate(t);
          })}
        </div>
      </Popup>
    );
  }

  renderInterfacePopup() {
    if (!this.state.editingInterface) {
      return null;
    }
    if (this.state.savingInterface) {
      return (
        <Popup title={`Saving changes`} maxWidth={600} hasPadding>
          <div className="flex flex-center">
            <FontAwesome className="spinner" name="spinner fa-pulse fa-fw" />
          </div>
        </Popup>
      );
    }
    if (this.state.showConfirmInterfaceDelete) {
      const usingInterface = _.filter(this.state.userTypes, (t) => {
        return t.Interface === this.state.editingInterface.Id;
      });
      return (
        <Popup
          title={`Are you sure?`}
          noDotTitle
          maxWidth={600}
          hasPadding
          buttons={[
            {
              type: 'primary',
              onClick: this.confirmDeleteInterface,
              isActive: !_.isEmpty(this.state.editingInterface.Title),
              text: !_.isEmpty(usingInterface) ? 'Save and Delete' : 'Delete',
            },
            {
              type: 'tertiary',
              onClick: this.cancelDeleteInterface,
              isActive: true,
              text: 'Cancel',
            },
          ]}
        >
          {!_.isEmpty(usingInterface) ? (
            <div>
              <p className="interfacePopup_deleteWarning">
                <span className="interfacePopup_deleteWarning-userTypes">
                  {usingInterface.map((t, i) => {
                    let result = '';
                    if (i > 0) {
                      if (i + 1 === usingInterface.length) {
                        result += ' & ';
                      } else {
                        result += ', ';
                      }
                    }
                    return result + t.displayName;
                  })}
                </span>{' '}
                {getPluralOptions(usingInterface.length, 'is', 'are')} currently using this view. Which view do you want to switch to?
              </p>
              <p className="interfacePopup_userTypeSectionHeadline">Select app view</p>
              <div className="interfacePopup_userTypeSection">
                <div
                  className="interfacePopup_userType"
                  onClick={() => {
                    this.interfaceDeleteSwitchTo(null);
                  }}
                >
                  <RadioButton isActive={this.state.interfaceDeleteSwitchTo} val={null} style={{ marginRight: 8 }} single />
                  <p className="interfacePopup_userType_title">General</p>
                </div>
                {this.getAppInterfaces().map((iFace) => {
                  if (iFace.Id === this.state.editingInterface.Id) {
                    return null;
                  }
                  return (
                    <div
                      className="interfacePopup_userType"
                      onClick={() => {
                        this.interfaceDeleteSwitchTo(iFace.Id);
                      }}
                    >
                      <RadioButton isActive={this.state.interfaceDeleteSwitchTo} val={iFace.Id} style={{ marginRight: 8 }} single />
                      <p className="interfacePopup_userType_title">{iFace.Title}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </Popup>
      );
    }
    return (
      <Popup
        title={`Edit ${this.state.editingInterface.Title}`}
        maxWidth={600}
        hasPadding
        buttons={[
          {
            type: 'outlined',
            onClick: this.goToDeleteInterface,
            isActive: true,
            text: 'Delete View',
          },
          {
            type: 'primary',
            onClick: this.saveEditInterface,
            isActive: !_.isEmpty(this.state.interfaceTitleInput),
            text: 'Done',
          },
        ]}
      >
        <GenericInput
          id="interfaceTitleInput"
          type="text"
          value={this.state.interfaceTitleInput}
          onChange={(e) => this.handleChange(e)}
          maxLength={20}
        />
        <p className="interfacePopup_userTypeSectionHeadline">Apply to user types</p>
        <div className="interfacePopup_userTypeSection">
          {this.state.interfaceUserTypes.map((t) => {
            return (
              <div
                className="interfacePopup_userType"
                onClick={() => {
                  this.toggleUserTypeInterface(t);
                }}
              >
                <CheckBox
                  style={{ marginBottom: 0 }}
                  className="interfacePopup_userType_checkbox"
                  isActive={t.Interface === this.state.editingInterface.Id}
                />
                <p className="interfacePopup_userType_title">{t.displayName}</p>
              </div>
            );
          })}
        </div>
      </Popup>
    );
  }

  renderColourPickerPopup() {
    if (!this.state.colourPickerOpen) {
      return null;
    }
    return (
      <Popup
        title={`Colour Picker`}
        maxWidth={600}
        hasPadding
        buttons={[
          {
            type: 'primary',
            onClick: () => {
              this.setState({ colourPickerOpen: !this.state.colourPickerOpen });
            },
            isActive: true,
            text: 'Done',
          },
        ]}
      >
        <div className="flex flex-center">
          <ChromePicker
            color={this.state.colour}
            onChangeComplete={this.handleColourChange}
            disableAlpha
            className="chrome-picker-noShadow"
          />
        </div>
      </Popup>
    );
  }

  renderColourQuickChoicesCircle(colour, selectedColour, type) {
    return (
      <div
        className={`colourQuickChoices_circle${selectedColour === colour ? ' colourQuickChoices_circle-selected' : ''}`}
        style={{ backgroundColor: colour }}
        onClick={() => {
          if (type === 'footer') {
            this.handleFooterColourChange(colour);
          } else if (type === 'pattern') {
            this.handlePatternColourChange(colour);
          } else {
            this.handleColourChange(colour);
          }
        }}
      >
        <FontAwesome name="check" className="colourQuickChoices_circle_tick" />
      </div>
    );
  }

  renderColourQuickChoices(type) {
    let content;
    let selectedColour = this.state.colour;
    if (type === 'footer') {
      selectedColour = this.state.footerColour;
    } else if (type === 'pattern') {
      selectedColour = this.state.patternColour || '#fff';
    }
    switch (this.state.selectedQuickChoice) {
      case 'colourPicker':
        content = (
          <div className="flex flex-between colourQuickChoices">
            <ChromePicker
              color={selectedColour}
              onChangeComplete={(colour) => {
                if (type === 'footer') {
                  this.handleFooterColourChange(colour);
                } else if (type === 'pattern') {
                  this.handlePatternColourChange(colour);
                }
              }}
              disableAlpha
              className="chrome-picker-noShadow"
            />
          </div>
        );
        break;
      case 'vibrant':
        content = (
          <div className="flex flex-between colourQuickChoices">
            <div className="colourQuickChoices_column">
              {this.renderColourQuickChoicesCircle('#c44569', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#f1a459', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#38ada9', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#7064a7', selectedColour, type)}
            </div>
            <div className="colourQuickChoices_column">
              {this.renderColourQuickChoicesCircle('#ea4c88', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#e8bb67', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#60a3bc', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#546de5', selectedColour, type)}
            </div>
            <div className="colourQuickChoices_column">
              {this.renderColourQuickChoicesCircle('#e66767', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#94d0c5', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#5badda', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#dfad8c', selectedColour, type)}
            </div>
            <div className="colourQuickChoices_column">
              {this.renderColourQuickChoicesCircle('#ff6c5c', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#7abf9e', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#9fcfd9', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#dd91a7', selectedColour, type)}
            </div>
            <div className="colourQuickChoices_column">
              {this.renderColourQuickChoicesCircle('#f19066', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#a1ab94', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#a67db7', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#596275', selectedColour, type)}
            </div>
          </div>
        );
        break;
      case 'soft':
        content = (
          <div className="flex flex-between colourQuickChoices">
            <div className="colourQuickChoices_column">
              {this.renderColourQuickChoicesCircle('#eacdd0', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#a4c9d7', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#c6b9c0', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#cdc4af', selectedColour, type)}
            </div>
            <div className="colourQuickChoices_column">
              {this.renderColourQuickChoicesCircle('#d6b7b1', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#eaf2f4', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#cce0db', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#b9bee4', selectedColour, type)}
            </div>
            <div className="colourQuickChoices_column">
              {this.renderColourQuickChoicesCircle('#9bd3cb', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#f1d7bb', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#fcbc7b', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#caeff5', selectedColour, type)}
            </div>
            <div className="colourQuickChoices_column">
              {this.renderColourQuickChoicesCircle('#e0dceb', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#b1becd', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#e6cfc5', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#cfd6df', selectedColour, type)}
            </div>
            <div className="colourQuickChoices_column">
              {this.renderColourQuickChoicesCircle('#f39ca4', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#c0adc5', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#fcbca4', selectedColour, type)}
              {this.renderColourQuickChoicesCircle('#c5ceb9', selectedColour, type)}
            </div>
          </div>
        );
        break;
      default:
        content = null;
        break;
    }
    return (
      <div>
        <Tabs
          onSelectTab={this.selectQuickChoice.bind(this)}
          selectedTab={this.state.selectedQuickChoice}
          tabs={this.getQuickChoiceTabs(type)}
          containerStyle={type === 'header' ? { justifyContent: 'center', paddingLeft: 30 } : {}}
        />
        {content}
      </div>
    );
  }

  renderColourQuickChoicesPopup() {
    if (!this.state.colourQuickChoicesOpen) {
      return null;
    }
    return (
      <Popup
        title={`Quick Choices`}
        maxWidth={500}
        hasPadding
        buttons={[
          {
            type: 'primary',
            onClick: () => {
              this.setState({ colourQuickChoicesOpen: !this.state.colourQuickChoicesOpen });
            },
            isActive: true,
            text: 'Done',
          },
        ]}
      >
        {this.renderColourQuickChoices('header')}
      </Popup>
    );
  }

  renderPatternsOption(url, title, i) {
    return (
      <div
        className={`featurePicker_option featurePicker_option-pattern${
          this.state.selectedPattern === url ? ' featurePicker_option-selected' : ''
        }`}
        onClick={() => {
          this.selectPatternOption(url);
        }}
        key={i}
      >
        <div className="featurePicker_mainSection_tile_inner">
          <div className="featurePicker_mainSection_tile_footer">
            <div className="featurePicker_mainSection_tile_footer_inner">
              <FontAwesome name="check" className="featurePicker_mainSection_tile_footer_tick" />
              <p className="featurePicker_mainSection_tile_footer_text truncate">{title}</p>
            </div>
          </div>
          <div className="featurePicker_mainSection_tile_image">
            <div
              className="featurePicker_mainSection_tile_image_inner"
              style={{ backgroundImage: `url(${url})`, backgroundColor: this.state.colour }}
            ></div>
          </div>
        </div>
      </div>
    );
  }

  renderPatternOptionsPopup() {
    if (!this.state.patternOptionsOpen) {
      return null;
    }
    return (
      <Popup
        buttons={[
          {
            type: 'primary',
            onClick: this.closePatternOptions,
            isActive: true,
            text: 'Done',
          },
        ]}
        onClose={this.closePatternOptions}
        hasPadding
      >
        <div className="featurePicker_popup_inner">
          {this.renderPreview()}
          <div className="featurePickerRight featurePickerRight-options">
            <div className="featurePicker_popup_titleContainer" style={{ paddingLeft: 10 }}>
              <span className="popup_title featurePicker_popup_title-faded">App Options</span>
              <FontAwesome name="angle-right" className="featurePicker_popup_titleIcon featurePicker_popup_title-faded" />
              <span className="popup_title">Patterns</span>
            </div>
            <div className="featurePicker_mainSection featurePicker_mainSection-grid">
              <div className="featurePicker_mainSection_widgetContainer">
                <div className="featurePicker_mainSection_empty">
                  <div className="flex">
                    <Switch
                      className="featurePicker_gridToggle_toggle"
                      checked={this.state.usePatternColour}
                      onChange={(shouldUse) => {
                        this.setState({ usePatternColour: shouldUse });
                      }}
                      onColor={COLOUR_BRANDING_MAIN}
                      offColor={COLOUR_DUSK_LIGHTER}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      height={26}
                      width={50}
                    />
                    <p className="featurePicker_gridToggle_label">Custom Pattern Colour</p>
                  </div>
                  {this.state.usePatternColour && <div className="marginTop-16">{this.renderColourQuickChoices('pattern')}</div>}
                </div>
                {_.map(this.state.patternOptions, (p, i) => {
                  return this.renderPatternsOption(p.url, p.title || `Pattern ${i + 1}`, i);
                })}
                {isTheBest(this.props.auth, true) && (
                  <ImageInput
                    ref="patternInput"
                    style={{ width: 250, height: 250 }}
                    containerStyle={{ display: 'inline-block', width: 250, height: 250 }}
                    noCompress
                    refreshCallback={this.onPatternUpdated.bind(this)}
                    simpleStyle
                    noMenu
                    disableRemove
                    onlyAllowUpload
                    noDownload
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Popup>
    );
  }

  renderAddInterfacePopup() {
    if (!this.state.showAddInterface) {
      return null;
    }
    if (this.state.savingInterface) {
      return (
        <Popup
          title={`Create New View`}
          maxWidth={600}
          hasPadding
          buttons={[
            {
              type: 'primary',
              onClick: this.saveNewInterface,
              isActive: !_.isEmpty(this.state.interfaceTitleInput),
              text: 'Done',
            },
            {
              type: 'tertiary',
              onClick: this.closeAddInterface,
              isActive: true,
              text: 'Cancel',
            },
          ]}
        >
          <div className="flex flex-center">
            <FontAwesome className="spinner" name="spinner fa-pulse fa-fw" />
          </div>
        </Popup>
      );
    }
    return (
      <Popup
        title={`Create New View`}
        maxWidth={600}
        hasPadding
        buttons={[
          {
            type: 'primary',
            onClick: this.saveNewInterface,
            isActive: !_.isEmpty(this.state.interfaceTitleInput),
            text: 'Done',
          },
          {
            type: 'tertiary',
            onClick: this.closeAddInterface,
            isActive: true,
            text: 'Cancel',
          },
        ]}
      >
        <p className="interfacePopup_helperBox">
          A View is a different experience within the same community app. You can create a community staff feature set, a feature set for
          people linked closely to your users, you can divide by role etc.
        </p>
        <GenericInput
          id="interfaceTitleInput"
          type="text"
          value={this.state.interfaceTitleInput}
          onChange={(e) => this.handleChange(e)}
          maxLength={20}
          placeholder="Enter view name"
        />
        <p className="interfacePopup_userTypeSectionHeadline">Apply to user types</p>
        <div className="interfacePopup_userTypeSection">
          {this.state.interfaceUserTypes.map((t) => {
            return (
              <div
                className="interfacePopup_userType"
                onClick={() => {
                  this.toggleNewUserTypeInterface(t);
                }}
              >
                <CheckBox style={{ marginBottom: 0 }} className="interfacePopup_userType_checkbox" isActive={t.Interface === 'attach'} />
                <p className="interfacePopup_userType_title">{t.displayName}</p>
              </div>
            );
          })}
        </div>
      </Popup>
    );
  }

  renderAppOptions() {
    return (
      <div className="pageContainer relative flex flex-column featurePicker-appOptions">
        {this.renderLogoPopup()}
        {this.renderFooterPopup()}
        {this.renderSuccessPopup()}
        {this.renderColourPickerPopup()}
        {this.renderColourQuickChoicesPopup()}
        {this.renderPatternOptionsPopup()}
        {this.renderHeader()}
        <div className="featurePicker" style={{ visibility: this.state.loading ? 'hidden' : 'visible' }}>
          <div className="featurePickerLeft">{this.renderPreview()}</div>
          <div className="featurePickerRight paddingBottom-20">
            <div className="featurePickerRight_appOptionsCaret"></div>
            {this.renderPage()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.signupComplete) {
      return <SiteSignUpComplete preview={this.renderPreview()} logo={this.state.logo} />;
    }
    if (this.state.selectedTab === 'app') {
      return this.renderAppOptions();
    }
    return (
      <div className="pageContainer relative flex flex-column">
        <OnboardingStep step="intro_fp_1" />
        <OnboardingStep step="intro_fp_2" />
        <OnboardingStep step="intro_fp_options_1" />
        {this.renderOptionsPopup()}
        {this.renderSuccessPopup()}
        {this.renderTemplatePopup()}
        {this.renderInterfacePopup()}
        {this.renderAddInterfacePopup()}
        {this.renderHeader()}
        <OnboardingFade step="intro_fp_1" />
        {this.renderInterfaces()}
        <div className="featurePicker" style={{ visibility: this.state.loading ? 'hidden' : 'visible' }}>
          <div className="featurePickerLeft">
            <div className="featurePicker_appOptionsButton">
              <Button buttonType="boxed" onClick={this.goToAppOptions} isActive>
                App options
              </Button>
            </div>
            {this.renderPreview()}
          </div>
          <div className="featurePickerRight">
            {this.renderTabs()}
            {this.renderPage()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, nav } = state;
  return { auth, navData: nav, onboardingStep: nav.onboardingStep };
};

export default connect(mapStateToProps, { refreshAuthUser, setAuthLocation, setNavData })(withRouter(FeaturePicker));
